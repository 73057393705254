import styled from "styled-components";
import colors from "../helpers/colors";

const Container = styled.section`
  background-color: ${colors.primaire};
  border-top: 3px solid ${colors.secondaire};
  padding: 1rem 0;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  li {
    margin: 0 1rem;
  }
  a {
    color: #FFFFFF;
    
    &:hover {
      color: ${colors.secondaire};
    }
  }
`;

export default Container;