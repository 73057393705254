import styled from "styled-components";
import colors from "../../helpers/colors";

const Description = styled.article`
  margin: 1rem 0;
  
  h2 {
    margin-bottom: .5rem;
    color: ${colors.primaire};
  }
`;

export default Description;