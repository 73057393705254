import React from 'react';
import {List, Loader} from "../../styles/Pages/Adverts";
import AdvertCard from "./AdvertCard";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../styles/helpers/colors";
import Pagination from "./Pagination";

const ListCards = ({annonces, promiseInProgress, params, scrollTop, setParams, totalPages}) => {
  if (annonces !== null && promiseInProgress === false) {
    return (
      <>
        {annonces.data.length > 0 ? (
          <>
            <List>
              {annonces.data.map((annonce) => <AdvertCard key={annonce.id} data={annonce}/>)}
            </List>
            <Pagination scrollTop={scrollTop} params={params.page ?? 1} setParams={setParams} totalPages={totalPages ?? 1}/>
          </>
        ) : (
          <>
            <h2>Aucun bien n'a été trouvé.</h2>
          </>
        )}
      </>
    )
  } else {
    return (
      <Loader>
        <PulseLoader color={colors.secondaire}/>
      </Loader>
    )
  }
}

export default ListCards;