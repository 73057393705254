import React from 'react';
import {ReactComponent as LocalisationMarker} from "../../svg/localisation_marker.svg";
import Autosuggest from "react-autosuggest";
import {ErrorMessage} from "@hookform/error-message";

const Localisation = ({handleAdresseChange, autosuggestArgs, cityInputValue, register, errors}) => {
  return (
    <section className="group">
      <label htmlFor="position">A quelle adresse se situe votre bien ?*</label>
      <div className="field_icon">
        <i>
          <LocalisationMarker/>
        </i>
        <Autosuggest
          suggestions={autosuggestArgs.suggestions}
          onSuggestionsFetchRequested={
            autosuggestArgs.onSuggestionsFetchRequested
          }
          onSuggestionsClearRequested={
            autosuggestArgs.onSuggestionsClearRequested
          }
          getSuggestionValue={autosuggestArgs.getSuggestionValue}
          renderSuggestion={autosuggestArgs.renderSuggestion}
          renderSuggestionsContainer={autosuggestArgs.renderSuggestionsContainer}
          onSuggestionSelected={autosuggestArgs.onSuggestionSelected}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
          renderInputComponent={(inputProps) => (
            <input
              type="search"
              id="position"
              name="contact_mandat_estimation[adresse_search]"
              className="localisation"
              ref={register({
                required: "Vous devez renseigner l'adresse du bien à estimer"
              })}
              {...inputProps}
            />
          )}
          inputProps={{
            placeholder: 'Exemple : 8 rue du Docteur Herpin, 37000 Tours',
            value: cityInputValue,
            onChange: (e, { newValue }) => handleAdresseChange(newValue)
          }}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name="contact_mandat_estimation[adresse_search]"
        render={({messages}) => {
          return messages
            ? Object.entries(messages).map(([type, message]) => (
              <p key={type} className="error-message">
                {message}
              </p>
            ))
            : null;
        }}
      />
    </section>
  )
}

export default Localisation;