import styled from "styled-components";
import colors from "../helpers/colors";
import breakpoints from "../helpers/breakpoints";

const Container = styled.section`
  height: 90px;
  background-color: ${colors.primaire};
  //border-bottom: 2px solid ${colors.secondaire};
  box-shadow: 0 2px 3px rgba(0,0,0,.3);
  
  #btnAffiner {
    display: none;
  }

  @media (max-width: ${breakpoints.largestTabletL}) {
    height: auto;
    display: flex;
    flex-direction: column;
    
    #btnAffiner {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      padding: 0 1rem;
      
      svg {
        width: 45px;
        fill: #FFFFFF;
      }
    }
  }
`;

export default Container;