import styled from "styled-components";
import colors from "../helpers/colors";
import fonts from "../helpers/fonts";
import breakpoints from "../helpers/breakpoints";

const Menu = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 75px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  background-color: #FFFFFF;
  
  .navigation {
    display: flex;
    align-items: center;
    a {
      margin-left: 1rem;
      color: ${colors.primaire};
      font-family: ${fonts.bold};
      
      &:hover {
        color: ${colors.secondaire};
      }
      
      &:first-child {
        margin-left: 0;
      }
    }
  }
  
  button {
    cursor: pointer;
    display: none;
    justify-self: end;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    outline: none;
    grid-area: burger;

    span {
      right: .5rem;
    }

    span,
    span:before,
    span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 5px;
      width: 35px;
      background: ${colors.primaire};
      position: absolute;
      display: block;
      content: '';
      transition: all 300ms ease-in-out;
      margin-right: 1rem;
    }

    span:before {
      top: -10px;
    }

    span:after {
      bottom: -10px;
    }

    &.active span {
      background-color: transparent;

      &:before,
      &:after {
        top: 0;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  @media (max-width: ${breakpoints.largestTablet}) {
    .navigation {
      position: absolute;
      flex-direction: column;
      transform: translateX(101%);
      transition: all .3s ease-out;
      top: 0;
      right: 0;
      background-color: #FFFFFF;
      z-index: 9999;
      height: 100vh;
      overflow-x: hidden;
      padding: 1rem;
      border-left: 1px solid ${colors.primaire};
      box-shadow: 0 2px 4px rgba(0,0,0, .3);
      align-items: flex-start;
      
      a {
        margin: 1rem 0;
      }
      
      &.active {
        transform: translateX(0px);
      }
    }
    button {
      display: inline;
    }
  }
`;

export default Menu;