import styled, {css} from "styled-components";
import colors from "../helpers/colors";
import fonts from "../helpers/fonts";

const Suggestion = styled.div`
  padding: .5rem 1rem;
  
  .codePostal {
    font-size: .9rem;
  }
  .ville {
    font-family: ${fonts.bold};
    color: ${colors.primaire};
  }
	${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${colors.tertiaire};
      cursor: pointer;
    `
  };
`;

export default Suggestion;
