import React, {useEffect, useState} from 'react';
import TitleH1 from "../styles/TitleH1";
import {Container, Content} from "../styles/Pages/Accueil";
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import ImgTransaction from "../public/images/transaction.jpg";
import Header from "../styles/Header";
import ApiService from "../services/ApiService";
import {FRONT_BASE_URL} from "../constants/routes";
import ListActus from "../components/Accueil/ListActus";
import TitleH2 from "../styles/TitleH2";
import {Link} from "react-router-dom";

const Accueil = () => {
  const [banniere, setBanniere] = useState(null)
  const [actualites, setActualites] = useState(null);

  useEffect(() => {
    const callApi = async () => {
      const response = await ApiService.getBannieres();
      const bannieres = response.filter(r => r.isDefault === true && r.banniere.dimensions[0] > 768 )
      setBanniere(bannieres[0]);
    }
    callApi();
  }, [])

  useEffect(() => {
    const callApi = async () => {
      const response = await trackPromise(ApiService.getActualitesACDLI());
      if (response.length > 0) {
        setActualites(response);
      }
    }
    callApi();
  }, [])

  const { promiseInProgress } = usePromiseTracker();

  const renderBanniere = () => {
    if (banniere) {
      return (
        <picture>
          <img src={`${FRONT_BASE_URL}/${banniere.banniere.contentUrl}`} alt="Banque Populaire X Citya Immobilier"/>
        </picture>
      )
    } else {
      return (
        <picture className="loading" />
      )
    }
  }

  const renderActus = () => {
    if (actualites !== null && actualites.length > 0) {
      return (
        <>
          <TitleH2>Au cœur de l'actualité immobilière</TitleH2>
          <ListActus actualites={actualites} promiseInProgress={promiseInProgress} />
          <p className="sponso">Contenu sponsorisé par <a href="https://www.aucoeurdelimmo.com/" target="_blank" rel="noopener"><img
            src="https://media.sas-arche.com/apps_logos_default/acdli_inline.svg" alt="Logo Au Coeur de l'Immo"/></a></p>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <Header>
        {renderBanniere()}
        {/*<picture>
          <img src="https://www.citya.com/media/images/bannieres/605b198616f5f772806959.jpg" alt="Banque Populaire X Citya Immobilier"/>
        </picture>*/}
      </Header>
      <main>
        <TitleH1>Banque Populaire Val de France et Citya Immobilier,<br/>partenaires de vos projets immobiliers.<br/><span>Avec Coach Immo, quel que soit votre projet, vous êtes accompagné de A à Z !</span></TitleH1>
        <Container>
          <Content>
            <article>
              <p>Et si votre rêve d’acheter devenait réalité ? Rechercher son futur bien immobilier est un moment important dans la vie !</p>
              <p>
                À la Banque Populaire Val de France, donner un coup de pouce à vos projets et vous accompagner dans les moments importants de votre vie est notre vocation c’est pourquoi nous nous associons aujourd’hui à Citya Immobilier pour vous proposer un tout nouveau site de recherches de biens immobiliers, partout sur notre territoire.
              </p>
              <p>Avec Coach Immo, pour devenir propriétaire et élargir votre patrimoine vous êtes accompagné de A à Z !</p>
              <p className="textCenter"><Link to="/annonces" className="button-default">Voir les annonces</Link></p>
            </article>
            <picture>
              <img src={ImgTransaction} alt="Transaction" />
            </picture>
          </Content>
        </Container>
        {renderActus()}
      </main>
    </React.Fragment>
  )
}

export default Accueil;