import React from 'react';

const Hidden = ({estimation}) => {
  return(
    <>
      <input type="hidden" id="contact_mandat_estimation_adresse" name="adresse" value={estimation.adresse} />
      <input type="hidden" id="contact_mandat_estimation_codePostal" name="codePostal" value={estimation.codePostal} />
      <input type="hidden" id="contact_mandat_estimation_ville" name="ville" value={estimation.ville} />
      <input type="hidden" id="contact_mandat_estimation_latitude" name="latitude" value={estimation.latitude} />
      <input type="hidden" id="contact_mandat_estimation_longitude" name="longitude" value={estimation.longitude} />
    </>
  )
}

export default Hidden;