import _ from 'lodash';
import Accueil from "../pages/Accueil";
import Adverts from "../pages/Adverts";
import Advert from "../pages/Advert";
import Contact from "../pages/Contact";
import Estimer from "../pages/Estimer";

export const FRONT_BASE_URL = process.env.REACT_APP_CITYA_URL;

// routes utilisées par Axios
export const apiRoutes = {
  ANNONCES: `${FRONT_BASE_URL}/api/biens`,
  ANNONCE: `${FRONT_BASE_URL}/api/biens`,
  VILLES: `${FRONT_BASE_URL}/api/villes`,
  CONTACT_PROSPECT: `${FRONT_BASE_URL}/api/contact_prospects`,
  BANNIERES: `${FRONT_BASE_URL}/api/banniere_configurations`,
};

export const routes = [
  {
    path: '/',
    component: Accueil,
    name: 'home',
    breadcrumb: 'Accueil',
    label: 'Accueil'
  },
  {
    path: '/annonces',
    component: Adverts,
    name: 'adverts',
    breadcrumb: 'Annonces',
    label: 'Annonces'
  },
  {
    path: '/annonce/:id',
    component: Advert,
    name: 'advert',
    breadcrumb: 'Annonce',
    label: 'Annonce'
  },
  {
    path: '/estimer',
    component: Estimer,
    name: 'estimer',
    breadcrumb: 'Estimer',
    label: 'Estimer'
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact',
    breadcrumb: 'Contact',
    label: 'Contact'
  },
]

export const routesObj = _.mapKeys(routes, 'path');