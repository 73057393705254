import styled from "styled-components";
import colors from "../../helpers/colors";

const Images = styled.picture`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  
  .image-gallery-slide {
    background-color: #000000;
  }

  .image-gallery-icon:hover {
    color: ${colors.secondaire};
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 600px;
    min-height: 550px;
    //object-fit: cover;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
    object-fit: cover;
  }

  .fullscreen.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
    height: 100vh;
  }
`;

export default Images;