import styled from "styled-components";

const DpeGes = styled.section`
	width: 100%;
	margin-top: 1rem;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	text-align: center;
  
  figure {
    max-width: 400px;
    
    img {
      width: 100%;
      height: auto;
    }
  }
	
	figcaption {
		font-size: 0.8rem;
		font-style: italic;
		margin-top: 0.25rem;
	}
`;

export default DpeGes;
