import styled, {css} from "styled-components";
import colors from "../../helpers/colors";
import breakpoints from "../../helpers/breakpoints";

const Detail = styled.section`
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0,0,0,.15);
  padding: 1rem;

  h2 {
    margin-bottom: .5rem;
    color: ${colors.primaire};
  }
  
  section {
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  p {
    display: flex;
    align-items: center;
    background-color: ${colors.tertiaire};
    padding: .5rem;
  }
  
  svg {
    width: 20px;
    margin-right: .3rem;
    fill: ${colors.primaire};
  }
  
  ${({ prix }) =>
    prix &&
    css`
      section {
        margin-bottom: 1rem;
        grid-template-columns: repeat(2, 1fr);
        &:last-child {
          margin-bottom: 0;
        }
      }
		`
  };
  @media (max-width: ${breakpoints.largestLaptop}) {
    section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export default Detail;