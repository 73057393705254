import React, {useRef, useState} from 'react';
import useOutsideClick from "../../hooks/useOutsideClick";
import {Container, Content} from "../../styles/Dropdown";

const Dropdown = ({title, id, children}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const refDropdown = useRef();

  useOutsideClick(refDropdown, () => {
    if (open) setOpen(false);
  });

  return (
    <Container>
      <p id={id} className="title" onClick={() => toggle(!open)}>{title}</p>
      <Content className={open ? 'active' : ''} ref={refDropdown}>
        {children}
      </Content>
    </Container>
  )
}

export default Dropdown;