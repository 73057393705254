import {createGlobalStyle} from "styled-components";
import fonts from "./helpers/fonts";
import UbuntuRegular from '../public/fonts/Ubuntu-Regular.woff';
import UbuntuBold from '../public/fonts/Ubuntu-Bold.woff';
import colors from "./helpers/colors";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Ubuntu Regular';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url(${UbuntuRegular}) format('woff');
  }

  @font-face {
    font-family: 'Ubuntu Bold';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url(${UbuntuBold}) format('woff');
  }

  * {
    box-sizing: border-box;
    outline: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: #fafafa;
  }

  :after, :before {
    box-sizing: inherit;
  }

  p {
    line-height: 1.5;
    vertical-align: middle;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-weight: normal;
    text-decoration: none;
    font-family: ${fonts.main};
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, img {
    display: block;
  }

  button, input, optgroup, select, textarea {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }


  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  p {
    line-height: 1.6;
  }

  main {
    padding: 1rem;
    max-width: 1200px;
    margin: auto;
  }
  
  .textCenter {
    text-align: center;
  }
  
  .button-default {
    display: block;
    background-color: ${colors.primaire};
    color: #FFFFFF;
    padding: .5rem 1rem;
    margin: 1rem auto;
    max-width: 150px;
    transition: all .2s ease-out;
    
    &:hover {
      background-color: ${colors.secondaire};
    }
  }
  
  .sponso {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    
    img {
      margin-left: .5rem;
      max-width: 140px;
      width: 100%;
    }
  }
`;

export default GlobalStyles;