import React from 'react';
import {HashRouter, Redirect, Route, Switch,} from 'react-router-dom';

import {renderRoutes} from './helpers/utils';
import {routes} from "./constants/routes";

const NotFoundRedirect = () => <Redirect to="/" />;

const App = () => {
  return (
    <HashRouter>
      <Switch>
        {renderRoutes(routes)}
        <Route component={NotFoundRedirect} />
      </Switch>
    </HashRouter>
  );
}

export default App;
