import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {Container, Content, Form} from "../styles/Pages/Contact";
import TitleH1 from "../styles/TitleH1";
import {Button} from "../styles/SearchForm";
import ImageContact from "../public/images/contact.jpg";

const Contact = () => {
  const {register, errors, handleSubmit, reset} = useForm({
    criteriaMode: "all",
  });

  const [formMessage, setFormMessage] = useState({});
  const [isEnvoiEnCours, setIsEnvoiEnCours] = useState(false);

  useEffect(() => {
    const formMessage = document.querySelector(".form-message");
    let timeout;
    if (
      formMessage &&
      (formMessage.classList.contains("success") ||
        formMessage.classList.contains("error"))
    ) {
      timeout = setTimeout(() => {
        formMessage.classList.remove("success", "error");
        setFormMessage({});
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [formMessage]);

  const onSubmit = (data, e) => {
    setIsEnvoiEnCours((isEnvoiEnCours) => !isEnvoiEnCours);
  }


  return (
    <main>
      <TitleH1>Contactez-nous</TitleH1>
      <Container>
        <Content>
          <section>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="nom">Nom*</label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  placeholder="Renseignez votre nom"
                  ref={register({
                    required: "Vous devez renseigner votre nom de famille",
                    maxLength: {
                      value: 60,
                      message:
                        "Votre nom de famille ne peut pas dépasser 60 caractères",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="nom"
                  render={({messages}) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error-message">
                          {message}
                        </p>
                      ))
                      : null;
                  }}
                />
              </div>
              <div>
                <label htmlFor="nom">Prénom*</label>
                <input
                  type="text"
                  name="prenom"
                  id="nom"
                  placeholder="Renseignez votre prénom"
                  ref={register({
                    required: "Vous devez renseigner votre prénom",
                    maxLength: {
                      value: 60,
                      message:
                        "Votre prénom ne peut pas dépasser 60 caractères",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="prenom"
                  render={({messages}) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error-message">
                          {message}
                        </p>
                      ))
                      : null;
                  }}
                />
              </div>
              <div>
                <label htmlFor="email">Adresse E-mail*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Renseignez votre adresse email"
                  ref={register({
                    required: "Vous devez renseigner votre adresse email",
                    maxLength: {
                      value: 60,
                      message: "Votre email ne peut pas dépasser 60 caractères",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                      message: "Le format de votre adresse email n'est pas bon",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({messages}) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error-message">
                          {message}
                        </p>
                      ))
                      : null;
                  }}
                />
              </div>
              <div>
                <label htmlFor="telephone">Téléphone*</label>
                <input
                  type="tel"
                  name="telephone"
                  id="telephone"
                  placeholder="Renseignez votre téléphone"
                  ref={register({
                    required: "Vous devez renseigner votre numéro de téléphone",
                    maxLength: {
                      value: 20,
                      message:
                        "Votre numéro de téléphone ne peut pas dépasser 20 caractères",
                    },
                    pattern: {
                      value: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i,
                      message:
                        "Le format de votre numéro de téléphone n'est pas bon",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="telephone"
                  render={({messages}) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error-message">
                          {message}
                        </p>
                      ))
                      : null;
                  }}
                />
              </div>
              <div>
                <label htmlFor="message">Message*</label>
                <textarea name="message" id="message" ref={register({
                  required: "Vous devez renseigner un message",
                  minLength: {
                    value: 20,
                    message:
                      "Votre message doit faire minimum 20 caractères.",
                  },
                })}/>
                <ErrorMessage
                  errors={errors}
                  name="message"
                  render={({messages}) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error-message">
                          {message}
                        </p>
                      ))
                      : null;
                  }}
                />
              </div>
              <p>*Champ obligatoire</p>
              <Button type="submit">
                {isEnvoiEnCours ? "Envoi en cours..." : "Envoyer"}
              </Button>
            </Form>
          </section>
          <picture>
            <img src={ImageContact} alt="Contact" loading="lazy"/>
          </picture>
        </Content>
      </Container>
    </main>
  )
}

export default Contact;