import React from 'react';
import {ReactComponent as Appartement} from "../../svg/appartment.svg";
import {ReactComponent as Maison} from "../../svg/house.svg";
import {ReactComponent as Demeure} from "../../svg/demeure.svg";
import {ReactComponent as Parking} from "../../svg/parking.svg";
import {ReactComponent as Terrain} from "../../svg/terrain.svg";
import {ReactComponent as Local} from "../../svg/local.svg";
import {ReactComponent as LocalPro} from "../../svg/local_pro.svg";
import {ReactComponent as Industry} from "../../svg/industry.svg";

const IconTypologie = ({data}) => {
  if (data.typologie === "appartement" || data.typologie === "immeuble") {
    return <Appartement/>
  } else if (data.typologie === "maison") {
    return <Maison/>
  } else if (data.typologie === "demeure") {
    return <Demeure/>
  } else if (data.typologie === "parking") {
    return <Parking/>
  } else if (data.typologie === "terrain") {
    return <Terrain/>
  } else if (data.typologie === "local commercial" || data.typologie === "fonds de commerce") {
    return <Local/>
  } else if (data.typologie === "local industriel") {
    return <Industry/>
  } else if (data.typologie === "local professionnel") {
    return <LocalPro/>
  }
}

export default IconTypologie;