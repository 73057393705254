import styled from "styled-components";

const Content = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  section {
    flex: 0 0 50%;
    max-width: 50%;
    p {
      margin: 0 0 1rem;
    }
  }
  
  picture {
    flex: 0 0 50%;
    max-width: 50%;
    
    img {
      padding: 1rem;
      width: 100%;
    }
  }
`;

export default Content;