import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {capitalize, renderAnnoncePrice} from "../helpers/utils";
import ApiService from "../services/ApiService";
import {Agence, Apercu, Container, Description, DetailBref, DpeGes, Images, Resume} from "../styles/Pages/Advert";
import ImageGallery from "react-image-gallery";
import IconTypologie from "../components/Advert/IconTypologie";
import IconPieces from "../components/Advert/IconPieces";
import {ReactComponent as Euro} from "../svg/euro.svg";
import {ReactComponent as Bed} from "../svg/bed.svg";
import {ReactComponent as Parking} from "../svg/parking.svg";
import {ReactComponent as Bath} from "../svg/bathtub.svg";
import {ReactComponent as Shower} from "../svg/shower.svg";
import {ReactComponent as Chauffage} from "../svg/heater.svg";
import {ReactComponent as Sun} from "../svg/sun.svg";
import {ReactComponent as Phone} from "../svg/phone-call.svg";
import {ReactComponent as Mail} from "../svg/mail.svg";
import parse from "html-react-parser";
import NumberFormat from "react-number-format";
import AdvertDetail from "../components/Advert/AdvertDetail";
import AdvertDetailPrix from "../components/Advert/AdvertDetailPrix";
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../styles/helpers/colors";
import {Loader} from "../styles/Pages/Adverts";
import Modal from "../components/Modal";
import {Content} from "../styles/Modal";
import ContactProspect from "../components/Advert/ContactProspect";

const Advert = () => {
  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClick = () => setIsModalOpen((isModalOpen) => !isModalOpen);

  let {id} = useParams();

  useEffect(() => {
    const callApi = async id => {
      const response = await trackPromise(ApiService.getAnnonce(id));
      setData(response);
    };
    callApi(id);
  }, [])

  const { promiseInProgress } = usePromiseTracker();

  const formatImagesObject = (images, reference) => {
    return images.map((image) => ({
      original: process.env.REACT_APP_CITYA_URL + image.contentUrl,
      originalAlt: `Photo numéro ${image.numero}, bien référence ${reference}`,
      thumbnail: process.env.REACT_APP_CITYA_URL + image.contentUrl,
      thumbnailAlt: `Photo numéro ${image.numero}, bien référence ${reference}`,
    }));
  };

  const renderTitleH1 = () => {
    return (
      <h1>
        <span className="icon">
          <IconTypologie data={data} />
          <span>{capitalize(data.typologie)}<br/><span className="ref">Ref: {data.affNum}</span></span>
        </span>
        <span className="icon">
          <IconPieces data={data} />
          <span>
            {data.nbrePieces > 0 ? `${data.nbrePieces} pièce${data.nbrePieces > 1 ? "s" : ""} ` : ""}
            {data.surfaceBien > 0 ? `${data.surfaceBien}m²` : "surface non disponible"}<br/>
            <span className="ref">{data.ville.ville} ({data.ville.codePostal})</span>
          </span>
        </span>
      </h1>
    )
  }

  const renderDetailBref = () => {
    if (data.nbreChambres !== null || data.nbreParking !== null || data.nbreSalleBain !== null || data.nbreSalleEau !== null || data.modeChauffage !== null || data.exposition !== null) {
      return (
        <DetailBref>
          {!!parseInt(data.nbreChambres) && (
            <p><Bed/>{data.nbreChambres} chambre{data.nbreChambres > 1 ? 's' : ""}</p>
          )}
          {!!parseInt(data.nbreParking) && (
            <p><Parking/>{data.nbreParking} parking</p>
          )}
          {!!parseInt(data.nbreSalleBain) && (
            <p><Bath/>{data.nbreSalleBain} salle de bain</p>
          )}
          {!!parseInt(data.nbreSalleEau) && (
            <p><Shower/>{data.nbreSalleEau} salle d'eau</p>
          )}
          {!!data.modeChauffage && (
            <p><Chauffage/>{data.modeChauffage.libelle}</p>
          )}
          {!!data.exposition && (
            <p><Sun/>Exposition {data.exposition.libelle}</p>
          )}
        </DetailBref>
      )
    }
  }

  if (data !== null && promiseInProgress === false ) {
    return (
      <>
        <main>
          <Container>
            <Apercu>
              <Images>
                <ImageGallery lazyLoad={true} items={formatImagesObject(data.images, data.affNum)} thumbnailPosition={"bottom"} showPlayButton={false} />
              </Images>
              <Resume>
                {renderTitleH1()}
                <h2 className="icon"><Euro/>{parse(renderAnnoncePrice(data))} €</h2>
                {renderDetailBref()}
                <Agence>
                  <picture>
                    <img src={`https://media.sas-arche.com/entities_logos_shadow/${data.importConfiguration.agence.id}.svg`} alt={data.importConfiguration.agence.nomCommercial} loading="lazy" />
                  </picture>
                  <address>
                    <a target="_blank" rel="noopener" href={`${process.env.REACT_APP_CITYA_URL}/agences-immobilieres/${data.importConfiguration.agence.ville.realSlug}/${data.importConfiguration.agence.id}`}>
                      {data.importConfiguration.agence.nomCommercial}
                    </a><br/>
                    {data.importConfiguration.agence.adresse}<br/>
                    {data.importConfiguration.agence.ville.codePostal} {data.importConfiguration.agence.ville.ville}
                  </address>
                </Agence>
                <button type="button" title="Téléphone">
                  <a href={`tel:${data.importConfiguration.agence.telephone}`}><Phone/><NumberFormat value={data.importConfiguration.agence.telephone} displayType={"text"} format="## ## ## ## ##"/></a>
                </button>
                <button type="button" title="Contacter par mail" onClick={(e) => handleModalClick(e)}>
                  <p><Mail/>Contacter par mail</p>
                </button>
              </Resume>
            </Apercu>
            <Description>
              <h2>Description du bien</h2>
              <p>{data.commentaire}</p>
            </Description>
            <AdvertDetail data={data}/>
            <DpeGes>
              <figure>
                <img
                  loading="lazy"
                  src={`https://www.citya.com/dpe/${data.dpe}/400`}
                  alt="Graphique DPE"
                />
                <figcaption>DPE</figcaption>
              </figure>
              <figure>
                <img
                  loading="lazy"
                  src={`https://www.citya.com/ges/${data.ges}/400`}
                  alt="Graphique GES"
                />
                <figcaption>GES</figcaption>
              </figure>
            </DpeGes>
            <AdvertDetailPrix data={data}/>
          </Container>
        </main>
        <Modal isOpen={isModalOpen} handleClick={handleModalClick}>
          <Content>
            <h2>Vous êtes intéréssé ? Prenez contact !</h2>
            <ContactProspect advert={data}/>
          </Content>
        </Modal>
      </>
    )
  } else {
    return (
      <Loader>
        <PulseLoader color={colors.secondaire}/>
      </Loader>
    )
  }
};

export default Advert;