import styled from "styled-components";
import colors from "./helpers/colors";
import fonts from "./helpers/fonts";

const TitleH2 = styled.h1`
  color: ${colors.primaire};
  font-family: ${fonts.bold};
  margin: 2rem 0 1rem;
`;

export default TitleH2;