import React, {useEffect, useState} from "react";
import {MapBienIci} from "../../styles/Pages/Adverts";
import Marker from "../../public/images/marker.svg"
import {capitalize, renderAnnoncePrice} from "../../helpers/utils";
import parse from 'html-react-parser';

const Map = ({annonces, currentFilters}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [coreLoaded, setCoreLoaded] = useState(false);
  const [mapKimono, setMapKimono] = useState();
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const core = document.createElement('script');
    core.src = '//core.bienici.com/api.js?api_key=SB59B7U125B7WPVNVCYHDYW2VZZR18Q1NYVUARM0';
    core.id = 'core';
    core.type = 'application/javascript';
    core.addEventListener('load', ()=>setCoreLoaded(true));
    document.head.append(core);
    const script = document.createElement('script');
    script.src = '//www.bienici.com/api.js?api_key=SB59B7U125B7WPVNVCYHDYW2VZZR18Q1NYVUARM0';
    script.id = 'map';
    script.type = 'application/javascript';
    script.addEventListener('load', ()=>setMapLoaded(true));
    document.head.append(script);

  }, []);

  useEffect(() => {
    if (!mapLoaded && !coreLoaded) return;
    const kimonoMap = kimono.addMap({
      container: "#bienIci",
      center: { lat: 47.909635430689974, lng: 1.8770830432970271 },
      zoom: 8,
      draggableTouch: true,
      scrollwheel: true,
      sunPositionSelector: false,
    });
    kimonoMap.on("error", function (err) {
      console.error(err);
    });
    kimonoMap.on("load", function () {
      setMapKimono(kimonoMap);
    });
  }, [mapLoaded]);

  useEffect(() => {
    if (mapKimono !== undefined) {
      let myLatlng = new f4.map.LatLng(-25.363882, 131.044922);
      if (markers.length > 0) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        setMarkers([]);
      }
      let infowindow = new f4.map.InfoWindow();

      for (let index = 0; annonces.data[index]; index++) {
        const marker = new f4.map.Marker({
          position: new f4.map.LatLng(
            annonces.data[index].latitude,
            annonces.data[index].longitude
          ),
          map: mapKimono.map,
          icon: {
            url: Marker,
            scaledSize: new f4.map.Size(25, 25),
          },
        });
        setMarkers(oldArray => [...oldArray, marker]);

        const ContentWindow =
          '<div class="infoWindow">' +
          "<picture>" +
          '<img src="'+ process.env.REACT_APP_CITYA_URL + annonces.data[index].images[0].contentUrl +'"/>' +
          "</picture>" +
          "<div>" +
          "<p>" +
          `<a href="/#/annonce/${annonces.data[index].id}">${capitalize(annonces.data[index].typologie)} <strong>${annonces.data[index].nbrePieces !== null ? (`${annonces.data[index].nbrePieces} pièces`) : ""} ${annonces.data[index].surfaceBien}m²</strong></a>` +
          "</p>" +
          "<p class='ville'>" +
          `${annonces.data[index].ville.ville} (${annonces.data[index].ville.codePostal})` +
          "</p>" +
          "<p>" +
            `<strong>${parse(renderAnnoncePrice(annonces.data[index]))} €</strong>` +
          "</p>" +
          "</div>" +
          "</div>";
        f4.map.event.addListener(marker,'click', (function(marker,ContentWindow){
          return function() {
            infowindow.close();
            infowindow.setContent(ContentWindow);
            infowindow.open(mapKimono.map,marker);
          };
        })(marker,ContentWindow));
      }
    }
  }, [annonces, mapKimono])

  return (
    <>
      {setMapLoaded ? <MapBienIci id="bienIci" /> : ""}
    </>
  )
}

export default Map;