import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #888888;
  line-height: 30px;
  margin: .5rem;
  
  &::placeholder {
    font-size: .8rem;
  }
`;

export default Input;