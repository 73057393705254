import React from 'react';
import {Loader} from "../../styles/Pages/Adverts";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../styles/helpers/colors";
import {List} from "../../styles/Pages/Accueil";
import Actu from "./Actu";

const ListActus = ({actualites, promiseInProgress}) => {
  if (actualites !== null && promiseInProgress === false) {
    return (
      <>
        <List>
          {actualites.map((actu) => <Actu key={actu.id} data={actu} />)}
        </List>
      </>
    )
  } else {
    return (
      <Loader>
        <PulseLoader color={colors.secondaire}/>
      </Loader>
    )
  }
}

export default ListActus;