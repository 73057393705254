import styled from "styled-components";
import colors from "../../helpers/colors";
import fonts from "../../helpers/fonts";

const Resume = styled.article`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 1rem;
  
  svg {
    fill: ${colors.primaire};
  }
  
  h1 {
    font-size: 1.3rem;
    line-height: 1;
    color: ${colors.primaire};
  }
  
  h2 {
    color: ${colors.primaire};
    font-family: ${fonts.bold};
  }
  
  .icon {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    
    svg {
      width: 50px;
      height: 50px;
      margin-right: 1rem;
    }
    
    span {
      font-family: ${fonts.bold};
    }
  }
  .ref {
    font-size: .9rem;
    color: ${colors.secondaire};
  }
  
  button {
    display: block;
    width: 100%;
    background-color: ${colors.primaire};
    cursor: pointer;
    border: 0;
    padding: 0;
    transition: background-color .2s ease-out;
    
    &:last-child {
      margin-top: 1rem;
    }
    
    &:hover {
      background-color: ${colors.secondaire};
    }
    
    a, p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: .7rem 0;
      color: #FFFFFF;
    }
    
    svg {
      fill: #FFFFFF;
      width: 20px;
      height: 20px;
      margin-right: .5rem;
    }
  }
`;

export default Resume;