import styled, {css} from "styled-components";
import colors from "../helpers/colors";

const Element = styled.p`
	padding: .5rem;
  line-height: 1;
  margin: 0 .3rem;
	box-sizing: border-box;
	color: ${colors.primaire};
	fill: ${colors.primaire};
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 3px 4px rgba(0,0,0,.18);
	
	&:hover {
		color: ${colors.primaire};
    background-color: ${colors.tertiaire};
		fill: ${colors.primaire};
	}
	
	${({ disabled }) =>
		disabled &&
		css`
			cursor: default;
			pointer-events: none;
      background-color: transparent;
      box-shadow: none;
      fill: gray;
		`
	};
  
  ${({ activated }) =>
          activated &&
    css`
			cursor: default;
			pointer-events: none;
      background-color: ${colors.primaire};
      fill: #FFFFFF;
      color: #FFFFFF;
      box-shadow: none;
		`
  };
	
	svg {
		width: 0.8rem;
		height: 0.8rem;
	}
`;

export default Element;
