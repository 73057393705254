import styled from "styled-components";
import colors from "../helpers/colors";

const Button = styled.button`
  height: 100%;
  width: 100%;
  border: 0;
  background-color: ${colors.secondaire};
  cursor: pointer;
  padding: .7rem;
  transition: background-color .2s ease-out;
  color: #FFFFFF;
  
  &:hover {
    background-color: ${colors.primaire};
  }
  
  img {
    margin: auto;
    width: 30px;
  }
  
  svg {
    fill: #FFFFFF;
    width: 40px;
    height: 40px;
  }
`;

export default Button;