import styled from "styled-components";
import fonts from "../../helpers/fonts";
import colors from "../../helpers/colors";

const CardActu = styled.article`
  cursor: pointer;
  background-color: #FFFFFF;
  box-shadow: 0 3px 4px rgba(0,0,0,.2);
  transition: all .2s ease-out;
  
  &:hover {
    box-shadow: 0 3px 4px rgba(0,0,0,.3);
  }
  picture {
    display: block;
    position: relative;
    height: 180px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  section {
    padding: .8rem;
    .titleACDLI {
      font-family: ${fonts.bold};
      margin-bottom: .5rem;
      color: ${colors.primaire};
    }
  }
`;

export default CardActu;