import styled from "styled-components";
import breakpoints from "../helpers/breakpoints";

const Content = styled.div`
  display: none;
  background-color: #FFFFFF;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 3px 4px rgba(0,0,0, .4);
  z-index: 99;
  
  &.active {
    display: block;
  }

  @media (max-width: ${breakpoints.largestTabletL}) {
    position: relative;
  }
`;

export default Content;