import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {Contact} from "../../styles/Pages/Advert";
import {Button} from "../../styles/SearchForm";
import ApiService from "../../services/ApiService";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import fr from 'react-phone-number-input/locale/fr'

const ContactProspect = ({advert}) => {
  const {control, register, handleSubmit, errors} = useForm({ criteriaMode: "all" });

  const [formMessage, setFormMessage] = useState({});
  const [isEnvoiEnCours, setIsEnvoiEnCours] = useState(false);

  useEffect(() => {
    const formMessage = document.querySelector(".form-message");
    let timeout;
    if (
      formMessage &&
      (formMessage.classList.contains("success") ||
        formMessage.classList.contains("error"))
    ) {
      timeout = setTimeout(() => {
        formMessage.classList.remove("success", "error");
        setFormMessage({});
      }, 60000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [formMessage]);

  const onSubmit = (data, e) => {
    data.agence = advert.importConfiguration.agence["@id"];
    data.affNum = advert.affNum;
    data.typeTransaction = "vente";
    data.source = "coachimmo.citya.com";
    // data.telephone.nationalNumber = data.telephone;
    setIsEnvoiEnCours((isEnvoiEnCours) => !isEnvoiEnCours);
    ApiService.postContactProspect(data).then(result => {
      setFormMessage({message: "Votre message nous a bien été transmis !", className: "success"});
      setIsEnvoiEnCours((isEnvoiEnCours) => !isEnvoiEnCours);
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setFormMessage({message: error.response.data["hydra:description"], className: "error"});
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setFormMessage({message: "Une erreur serveur est survenue, veuillez réessayer ultérieurement.", className: "error"});
      } else {
        // Something happened in setting up the request that triggered an Error
        setFormMessage({message: "Une erreur est survenue, veuillez réessayer ultérieurement.", className: "error"});
      }
      setIsEnvoiEnCours((isEnvoiEnCours) => !isEnvoiEnCours);
    })
    e.target.reset(); // reset after form submit
  }

  return (
    <Contact onSubmit={handleSubmit(onSubmit)}>
      <div className="item">
        <label htmlFor="nom">Nom*</label>
        <input
          type="text"
          name="nom"
          id="nom"
          placeholder="Renseignez votre nom"
          ref={register({
            required: "Vous devez renseigner votre nom de famille",
            minLength: {
              value: 2,
              message:
                "Votre nom de famille doit faire au moins 2 caractères",
            },
            maxLength: {
              value: 60,
              message:
                "Votre nom de famille ne peut pas dépasser 60 caractères",
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="nom"
          render={({messages}) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                <p key={type} className="error-message">
                  {message}
                </p>
              ))
              : null;
          }}
        />
      </div>
      <div className="item">
        <label htmlFor="nom">Prénom*</label>
        <input
          type="text"
          name="prenom"
          id="prenom"
          placeholder="Renseignez votre prénom"
          ref={register({
            required: "Vous devez renseigner votre prénom",
            minLength: {
              value: 2,
              message:
                "Votre prénom doit faire au moins 2 caractères",
            },
            maxLength: {
              value: 60,
              message:
                "Votre prénom ne peut pas dépasser 60 caractères",
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="prenom"
          render={({messages}) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                <p key={type} className="error-message">
                  {message}
                </p>
              ))
              : null;
          }}
        />
      </div>
      <div className="item">
        <label htmlFor="email">Adresse E-mail*</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Renseignez votre adresse email"
          ref={register({
            required: "Vous devez renseigner votre adresse email",
            maxLength: {
              value: 60,
              message: "Votre email ne peut pas dépasser 60 caractères",
            },
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: "Le format de votre adresse email n'est pas bon",
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({messages}) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                <p key={type} className="error-message">
                  {message}
                </p>
              ))
              : null;
          }}
        />
      </div>
      <div className="item">
        <label htmlFor="telephone">Téléphone*</label>
        <PhoneInputWithCountry
          defaultCountry="FR"
          control={control}
          labels={fr}
          name="telephone"
          id="telephone"
          placeholder="00 00 00 00 00"
          rules={{ required: true }}
        />
        <ErrorMessage
          errors={errors}
          name="telephone"
          render={({messages}) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                <p key={type} className="error-message">
                  {message}
                </p>
              ))
              : null;
          }}
        />
      </div>
      <div className="item">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" ref={register({
          required: "Vous devez renseigner un message",
          minLength: {
            value: 20,
            message:
              "Votre message doit faire minimum 20 caractères.",
          },
        })} defaultValue={`Bonjour, je souhaiterais avoir des informations supplémentaires sur ce bien (référence ${advert.affNum}). Merci.`}>
        </textarea>
        <ErrorMessage
          errors={errors}
          name="message"
          render={({messages}) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                <p key={type} className="error-message">
                  {message}
                </p>
              ))
              : null;
          }}
        />
      </div>
      <p>*Champ obligatoire</p>
      <div className="form-button-wrapper">
        {formMessage && (
          <p className={`form-message ${formMessage.className}`}>
            {formMessage.message}
          </p>
        )}
      </div>
      <Button type="submit">
        {isEnvoiEnCours ? "Envoi en cours..." : "Envoyer"}
      </Button>
    </Contact>
  )
}

export default ContactProspect;
