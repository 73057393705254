import React from 'react';
import {Route} from 'react-router-dom';

/**
 * Retourne un tableau d'éléments `<Route>`
 * @param routes Un tableau de config de routes
 */
export const renderRoutes = (routes) => routes.map((route) => (
    <Route key={route.name} path={route.path} exact>
        <route.component />
    </Route>
));

/**
 * Comme son nom l'indique, slugify une string
 * @param string
 */
export const slugify = string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().trim().toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
  .replace(/&/g, '-and-') // Replace & with 'and'
  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
  .replace(/\-\-+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, '') // Trim - from end of text
};

/**
 * Transforme une route donnée par API-Platform en objet de paramètres et filtres
 * @param route Une route commençant par /api
 */
export const getParamsObjectFromQuery = route => {
  //si la route n'existe pas ou n'a pas de paramètres, on renvoie null
  if (!route || !route.startsWith('/api/') || route.indexOf('?') === -1)
    return null;
  const paramsStr = route.substr(route.indexOf('?') + 1);
  //on récupère les différents paramètres au format string 'key=value' dans un tableau
  const params = paramsStr.split('&');
  //puis on boucle sur chaque paramètre et on met sa key en propriété dans un objet face à sa value
  let obj = {};
  params.forEach(param => {
    const [key, value] = param.split('=');
    obj[key] = value;
  });
  return obj;
};

/**
 * Transforme un objet de paramètres et filtres en route compréhensible pour API-Platform
 * @param params Un objet de paramètres à passer dans l'URL qui sera envoyée à l'API
 */
export const getQueryFromParamsObject = params => {
  let query = '';
  for (const property in params) {
    //si la value est null ou undefined, on oublie ce paramètre là
    if (params[property] == null)
      continue;
    //on s'assure de ne travailler qu'avec des tableaux et on boucle dessus
    //ça change rien pour la plupart des données, qui sont envoyées en string
    //et ça permet d'envoyer certaines données en tableau de strings pour pouvoir chercher plusieurs équivalences dans l'API
    //Exemple: pour les types de bien, pour la typologie "professionnel", on recherche à la fois "local commercial", local industriel"...
    const valuesArr = Array.isArray(params[property]) ? params[property] : [params[property]];
    if (Array.isArray(params[property])) {
      valuesArr.forEach(value => {
        query += (query === '' ? '?' : '&') + property + '[]=' + value;
      });
    }
    else
      query += (query === '' ? '?' : '&') + property + '=' + params[property];
  }
  return query;
};

/**
 * Prend une typologie et retourne les types de bien qui correspondent au niveau de l'API
 * @param typologie La typologie choisie dans l'interface du site
 */
export const findTypesBienFromTypologie = typologie => {
  if (typologie === 'terrain')
    return ['terrain', 'foret'];
  if (typologie === 'maison')
    return ['maison', 'demeure'];
  if (typologie === 'professionnel')
    return ['local commercial', 'local professionnel', 'fonds de commerce', 'local industriel'];
  return typologie;
};

/**
 * Ajoute une majuscule à la première lettre d'un mot et met le reste en minuscule
 * @param string La string à capitaliser
 */
export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Ajoute un espace entre les milliers dans un nombre donné
 * @param number
 */
export const separateThousands = number => {
  number = number.toString();
  let c = '',
    d = 0;
  while (number.match(/^0[0-9]/)) {
    number = number.substr(1);
  }
  for (let i = number.length - 1; i >= 0; i--) {
    c = (d !== 0 && d % 3 === 0) ? number[i] + ' ' + c : number[i] + c;
    d++;
  }
  return c;
};

/**
 * Retourne le prix formaté pour une annonce immo donnée, en fonction de si c'est une vente ou une location
 * @param annonce
 */
export const renderAnnoncePrice = annonce => {
  if (!annonce || (!annonce.location && !annonce.vente))
    return null;
  //affichage différent en fonction location ou vente
  //on utilise Math.round(x*100)/100 pour arrondir à deux chiffres après la virgule quand nécessaire
  return annonce ?
    separateThousands(Math.round(annonce.vente.prix * 100) / 100) : null
};

/**
 * Transforme un objet de paramètres contenant des données de type `[key]: string|int` en une string pour URL
 * @param params obj Un objet de paramètres
 * @param standalone bool Si ce n'est pas standalone, la string doit commencer par `&`
 */
export const generateQueryString = (params, standalone = true, prepend = '') => {
  let urlQueryString = prepend, index = 0;
  for (const key in params) {
    if (params[key]) {
      if (urlQueryString === '' && prepend === '' && standalone)
        urlQueryString += '?';
      else {
        if (!standalone || index > 0) {
          urlQueryString += '&';
        }
      }
      urlQueryString += `${key}=${params[key]}`;
    }
    index++;
  }
  return urlQueryString;
};
