import React from 'react';
import {ReactComponent as Appartement} from "../../svg/appartment_bold.svg";
import {ReactComponent as Maison} from "../../svg/house_bold.svg";

const TypeBien = ({register, handleTypeBienChange, setTypeBien}) => {
  return (
    <section className="group">
      <label>Type du bien à estimer*</label>
      <div className="choices">
        <label className="choice" htmlFor="contact_mandat_estimation_typeBien_0">
          <input type="radio"
                 name="typeBien"
                 id="contact_mandat_estimation_typeBien_0"
                 value="appartement"
                 ref={register({
                   required: true
                 })}
                 onClick={(e) => setTypeBien(e.target.value)}
                 onChange={handleTypeBienChange}
          />
          <span>
            <Appartement/>
            Appartement
          </span>
        </label>
        <label className="choice" htmlFor="contact_mandat_estimation_typeBien_1">
          <input type="radio"
                 name="typeBien"
                 id="contact_mandat_estimation_typeBien_1"
                 value="maison"
                 ref={register({
                   required: true
                 })}
                 onClick={(e) => setTypeBien(e.target.value)}
                 onChange={handleTypeBienChange}
          />
          <span>
            <Maison/>
            Maison
          </span>
        </label>
      </div>
    </section>
  )
}

export default TypeBien;