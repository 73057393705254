import styled from "styled-components";
import colors from "../../helpers/colors";
import fonts from "../../helpers/fonts";

const Content = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  
  h2 {
    color: ${colors.primaire};
    text-align: center;
    font-family: ${fonts.bold};
    margin: 0 0 2rem;
  }
  
  h3 {
    width: 100%;
    font-size: 1.2rem;
    color: ${colors.primaire};
    margin: 0 0 .5rem;
    font-family: ${fonts.bold};
  }
`;

export default Content;