import styled from "styled-components";
import breakpoints from "../../helpers/breakpoints";

const List = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: ${breakpoints.largestTabletL}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.largestTablet}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default List;