import styled from "styled-components";
import colors from "../helpers/colors";
import fonts from "../helpers/fonts";

const Content = styled.section`
	margin: 2rem 0 0;
  
  h2 {
    color: ${colors.primaire};
    font-family: ${fonts.bold};
  }
`;

export default Content;