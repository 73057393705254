import styled from "styled-components";

const Apercu = styled.section`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0,0,0,.15);
`;

export default Apercu;