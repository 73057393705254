import styled from "styled-components";
import breakpoints from "../helpers/breakpoints";

const LogoBpvf = styled.span`
  position: relative;
  margin-left: 2rem;
  line-height: 0;
  
  img {
    height: 50px;
  }

  @media (max-width: ${breakpoints.largestTablet}) {
    margin-left: 0;
    img {
      height: 30px;
    }
  }
`;

export default LogoBpvf;