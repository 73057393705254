import api from "../helpers/axios";
import {apiRoutes} from "../constants/routes";
import {getParamsObjectFromQuery, getQueryFromParamsObject} from "../helpers/utils";

const getAll = params => {
  const query = getQueryFromParamsObject(params);

  return api.get(apiRoutes.ANNONCES + query).then(response => ({
      data: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
      firstPage: getParamsObjectFromQuery(response.data['hydra:view']['hydra:first']),
      lastPage: getParamsObjectFromQuery(response.data['hydra:view']['hydra:last']),
      previousPage: getParamsObjectFromQuery(response.data['hydra:view']['hydra:previous']),
      nextPage: getParamsObjectFromQuery(response.data['hydra:view']['hydra:next']),
    }));
};

const getAnnonce = id => api.get(`${apiRoutes.ANNONCE}/${id}`).then(response => response.data);

const getVilles = params => {
  const query = getQueryFromParamsObject(params);

  return api.get(apiRoutes.VILLES + query).then(response => response.data);
};

const getAdresses = params => {
  const query = getQueryFromParamsObject(params);

  return api.get("https://api-adresse.data.gouv.fr/search/" + query).then(response => response.data.features)
}

const getBannieres = () => {
  return api.get(apiRoutes.BANNIERES).then(response => response.data['hydra:member']);
}

const getActualitesACDLI = () => {
  return api.get("https://api.aucoeurdelimmo.com/api/actualites?isActif=1&order[datePublication]=DESC&pagination=1&itemsPerPage=3&page=1").then(response => response.data['hydra:member'])
}

const postContactProspect = data => {
  return api.post(apiRoutes.CONTACT_PROSPECT, data).then(response => response.data)
}

const postEstimation = data => {
  return api.post("http://estimation-prix.q1c1.fr:8080/estimation", data).then(response => response.data)
}

export default {
  getAll,
  getAnnonce,
  getVilles,
  getAdresses,
  getBannieres,
  getActualitesACDLI,
  postContactProspect,
  postEstimation,
};