import React from 'react';
import {Card} from "../../styles/Pages/Adverts";
import parse from 'html-react-parser';
import {capitalize, renderAnnoncePrice} from "../../helpers/utils";
import {Link} from "react-router-dom";

const AdvertCard = ({data}) => {

  const renderTitle = () => {
    if (data.typologie === "maison" || data.typologie === "appartement") {
      return <>{capitalize(data.typologie)} <strong>{data.nbrePieces > 0 ? `${data.nbrePieces} pièce${data.nbrePieces > 1 ? "s" : ""} ` : ""} {data.surfaceBien > 0 ? ` ${data.surfaceBien}m²` : null}</strong></>
    } else {
      return <>{capitalize(data.typologie)} {data.surfaceBien > 0 ? <strong>{data.surfaceBien}m²</strong> : ""}</>
    }
  }
  return (
    <React.Fragment>
      <Card>
        <picture>
          <Link to={`/annonce/${data.id}`}>
            <img src={process.env.REACT_APP_CITYA_URL + data.images[0].contentUrl} alt={`${capitalize(data.typologie)} <strong>${data.nbrePieces} pièces ${data.surfaceBien}m²`} loading="lazy" />
          </Link>
        </picture>
        <section>
          <p className="title">
            <a href="#">{renderTitle()}</a><br/>
            <span>{data.ville.ville} ({data.ville.codePostal})</span>
          </p>
          <p className="price">
            <strong>{parse(renderAnnoncePrice(data))} €</strong><br/>
            <span>Ref: {data.affNum}</span>
          </p>
        </section>
      </Card>
    </React.Fragment>
  )
}

export default AdvertCard;