import React from 'react';
import {Detail} from "../../styles/Pages/Advert";
import isBoolean from "lodash/isBoolean";
import {ReactComponent as Chevron} from "../../svg/chevron.svg";

const AdvertDetail = ({data}) => {
  return (
    <Detail>
      <h2>Détails du bien</h2>
      <section>
        {!!data.affNum && (
          <p>
            <Chevron/>
            <span>Réf: {data.affNum}</span>
          </p>
        )}
        {isBoolean(data.meuble) && (
          <p>
            <Chevron/>
            <span>{data.meuble ? "Meublé" : "Non-meublé"}</span>
          </p>
        )}
        {!!parseInt(data.nbreChambres) && (
          <p>
            <Chevron/>
            <span>{data.nbreChambres} chambre{data.nbreChambres > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!data.surfaceHabitable && (
          <p>
            <Chevron/>
            <span>{data.surfaceHabitable}m² de surface habitable</span>
          </p>
        )}
        {!!data.surfaceSejour && (
          <p>
            <Chevron/>
            <span>{data.surfaceSejour}m² de séjour</span>
          </p>
        )}
        {!!parseInt(data.nbreSalleBain) && (
          <p>
            <Chevron/>
            <span>{data.nbreSalleBain} salle{data.nbreSalleBain > 1 ? "s" : ""} de bain</span>
          </p>
        )}
        {!!parseInt(data.nbreSalleEau) && (
          <p>
            <Chevron/>
            <span>{data.nbreSalleEau} salle{data.nbreSalleEau > 1 ? "s" : ""} d'eau</span>
          </p>
        )}
        {!!parseInt(data.nbreGarage) && (
          <p>
            <Chevron/>
            <span>{data.nbreGarage} garage{data.nbreGarage > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreBalcon) && (
          <p>
            <Chevron/>
            <span>{data.nbreBalcon} balcon{data.nbreBalcon > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreParking) && (
          <p>
            <Chevron/>
            <span>{data.nbreParking} parking{data.nbreParking > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreTerrasse) && (
          <p>
            <Chevron/>
            <span>{data.nbreTerrasse} terrasse{data.nbreTerrasse > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!data.anneeConstruction && (
          <p>
            <Chevron/>
            <span>Construction en {new Date(data.anneeConstruction).getFullYear()}</span>
          </p>
        )}
        {!!parseInt(data.nbreNiveaux) && (
          <p>
            <Chevron/>
            <span>{data.nbreNiveaux} niveau{data.nbreNiveaux > 1 ? "x" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreCaves) && (
          <p>
            <Chevron/>
            <span>{data.nbreCaves} cave{data.nbreCaves > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!data.surfaceDependance && (
          <p>
            <Chevron/>
            <span>{data.surfaceDependance}m² de dépendances</span>
          </p>
        )}
        {!!data.sousSol && (
          <p>
            <Chevron/>
            <span>Présence d'un sous-sol</span>
          </p>
        )}
        {!!data.superficieTotale && (
          <p>
            <Chevron/>
            <span>{data.superficieTotale}m² de superficie</span>
          </p>
        )}
        {!!data.natureTerre && (
          <p>
            <Chevron/>
            <span>{data.natureTerre}</span>
          </p>
        )}
        {!!data.typeRelief && (
          <p>
            <Chevron/>
            <span>{data.typeRelief}</span>
          </p>
        )}
        {!!data.cheminVidange && (
          <p>
            <Chevron/>
            <span>Présence d'un chemin de vidange</span>
          </p>
        )}
        {isBoolean(data.chasse) && (
          <p>
            <Chevron/>
            <span>Chasse {data.chasse ? "autorisée" : "interdite"}</span>
          </p>
        )}
        {!!data.distanceRouteGoudronne && (
          <p>
            <Chevron/>
            <span>À {data.distanceRouteGoudronne}km d'une route goudronnée</span>
          </p>
        )}
        {isBoolean(data.etatForetPlantation) && (
          <p>
            <Chevron/>
            <span>Plantation {data.etatForetPlantation ? "possible" : "impossible"}</span>
          </p>
        )}
        {!!data.etatPlantationAge && (
          <p>
            <Chevron/>
            <span>{data.etatPlantationAge} ans d'âge moyen de plantation</span>
          </p>
        )}
        {isBoolean(data.etatForetFutaie) && (
          <p>
            <Chevron/>
            <span>Futaie {data.etatForetFutaie ? "possible" : "impossible"}</span>
          </p>
        )}
        {!!data.etatFutaieVolume && (
          <p>
            <Chevron/>
            <span>{data.etatFutaieVolume}m³ exploitables en futaie</span>
          </p>
        )}
        {!!parseInt(data.nbreHabitation) && (
          <p>
            <Chevron/>
            <span>Présence de {data.nbreHabitation} habitation {data.nbreHabitation > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!data.surfaceHabitation && (
          <p>
            <Chevron/>
            <span>{data.surfaceHabitation}m² d'habitations</span>
          </p>
        )}
        {!!parseInt(data.nbreExploitation) && (
          <p>
            <Chevron/>
            <span>Présence de {data.nbreExploitation} exploitation{data.nbreExploitation > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!data.surfaceExploitation && (
          <p>
            <Chevron/>
            <span>{data.surfaceExploitation}m² d'exploitations</span>
          </p>
        )}
        {!!parseInt(data.nbreLot) && (
          <p>
            <Chevron/>
            <span>Contient {data.nbreLot} lot{data.nbreLot > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreAppartement) && (
          <p>
            <Chevron/>
            <span>Contient {data.nbreAppartement} appartement{data.nbreAppartement > 1 ? "s" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreNiveau) && (
          <p>
            <Chevron/>
            <span>{data.nbreNiveau} niveau{data.nbreNiveau > 1 ? "x" : ""}</span>
          </p>
        )}
        {!!parseInt(data.nbreCave) && (
          <p>
            <Chevron/>
            <span>{data.nbreCave} cave{data.nbreCave > 1 ? "s" : ""}</span>
          </p>
        )}
        {isBoolean(data.accesHandicape) && (
          <p>
            <Chevron/>
            <span>{data.accesHandicape ? "Possède un " : "Ne possède pas d'"}accès handicapé</span>
          </p>
        )}
        {!!data.immeubleCollectif && (
          <p>
            <Chevron/>
            <span>Immeuble collectif</span>
          </p>
        )}
        {!!data.immeubleIndependant && (
          <p>
            <Chevron/>
            <span>Immeuble indépendant</span>
          </p>
        )}
        {!!data.surfaceUtile && (
          <p>
            <Chevron/>
            <span>{data.surfaceUtile}m² de surface utile</span>
          </p>
        )}
        {!!data.surfaceDivisble && (
          <p>
            <Chevron/>
            <span>{data.surfaceDivisble}m² de surface divisible</span>
          </p>
        )}
        {!!data.surfaceMinimum && (
          <p>
            <Chevron/>
            <span>{data.surfaceMinimum}m² de surface minimum</span>
          </p>
        )}
        {!!data.monteCharge && (
          <p>
            <Chevron/>
            <span>Monte-charge</span>
          </p>
        )}
        {!!data.climatisation && (
          <p>
            <Chevron/>
            <span>Climatisation</span>
          </p>
        )}
        {!!data.proximiteAccessRoutier && (
          <p>
            <Chevron/>
            <span>Proche d'un accès routier</span>
          </p>
        )}
        {!!data.proximiteAccesToutTonnage && (
          <p>
            <Chevron/>
            <span>Proche d'un accès tout tonnage</span>
          </p>
        )}
        {!!data.proximiteAerogare && (
          <p>
            <Chevron/>
            <span>Proche d'une aérogare</span>
          </p>
        )}
        {!!data.proximiteGareRoutiere && (
          <p>
            <Chevron/>
            <span>Proche d'une gare routière</span>
          </p>
        )}
        {!!data.proximiteCommerces && (
          <p>
            <Chevron/>
            <span>Proche de commerces</span>
          </p>
        )}
        {!!data.proximiteMetroRER && (
          <p>
            <Chevron/>
            <span>Proche d'un métro/RER</span>
          </p>
        )}
        {!!data.proximiteSNCF && (
          <p>
            <Chevron/>
            <span>Proche d'une gare SNCF</span>
          </p>
        )}
        {!!data.proximiteVoieFluviale && (
          <p>
            <Chevron/>
            <span>Proche d'une voie fluviale</span>
          </p>
        )}
        {!!data.parkingType && (
          <p>
            <Chevron/>
            <span>{data.parkingType.libelle}</span>
          </p>
        )}
        {!!data.niveau && (
          <p>
            <Chevron/>
            <span>{data.niveau.libelle}</span>
          </p>
        )}
        {!!parseInt(data.numLot) && (
          <p>
            <Chevron/>
            <span>Numéro {data.numLot}</span>
          </p>
        )}
        {!!data.disponibilite && (
          <p>
            <Chevron/>
            <span>Disponibilité : {data.disponibilite.libelle}</span>
          </p>
        )}
        {!!data.cos && (
          <p>
            <Chevron/>
            <span>COS de {data.cos}</span>
          </p>
        )}
        {!!data.shon && (
          <p>
            <Chevron/>
            <span>{data.shon}m² de surface hors œuvre nette</span>
          </p>
        )}
        {!!data.surfaceTerrain && (
          <p>
            <Chevron/>
            <span>{data.surfaceTerrain}m² de surface</span>
          </p>
        )}
        {!!data.dateCA && (
          <p>
            <Chevron/>
            <span>Date de certificat d'aménagement au{" "}
              {formatDate(new Date(data.dateCA), "/", true)}</span>
          </p>
        )}
        {!!data.dateCU && (
          <p>
            <Chevron/>
            <span>Date de certificat d'urbanisme au{" "}
              {formatDate(new Date(data.dateCU), "/", true)}</span>
          </p>
        )}
        {!!data.hauteurMaxi && (
          <p>
            <Chevron/>
            <span>Hauteur maximum de {data.hauteurMaxi}m</span>
          </p>
        )}
        {!!data.longueurFacade && (
          <p>
            <Chevron/>
            <span>Façade de {data.longueurFacade}m</span>
          </p>
        )}
        {!!data.datePC && (
          <p>
            <Chevron/>
            <span>Permis de construire du{" "}{formatDate(new Date(data.datePC), "/", true)}</span>
          </p>
        )}
        {!!data.cloture && (
          <p>
            <Chevron/>
            <span>Clôture</span>
          </p>
        )}
      </section>
    </Detail>
  )
}

export default AdvertDetail