import styled from "styled-components";
import colors from "../../helpers/colors";

const Form = styled.form`
  width: 100%;
  
  .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    
    button {
      width: 135px;
      margin-left: auto;
      cursor: pointer;
      border: 0;
      background-color: ${colors.primaire};
      color: #FFFFFF;
      padding: .8rem;
    }
  }
  
  .field_icon {
    display: flex;
    align-items: center;
    position: relative;
    
    i {
      display: flex;
      align-items: center;
      padding: .5rem 1rem;
      margin-right: -1px;
      border: 1px solid #888;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
  
  label {
    color: ${colors.primaire};
    margin-bottom: .5rem;
    font-size: 1.2rem;
  }
  
  .react-autosuggest__container {
    width: 100%;
    
    input {
      width: 100%;
      font-size: 1.2rem;
      padding: .5rem;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border: 1px solid #888888;
      box-sizing: border-box;
    }
    
    .localisation {
      position: absolute;
      background-color: #FFFFFF;
      width: 70%;
      box-shadow: 0 3px 4px rgba(0,0,0, .4);
      z-index: 99;
    }
  }
  
  .choices {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 1rem;
    margin: .5rem 0;
    
    .choice {
      margin-bottom: 0!important;
      width: auto!important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
      
      &:checked {
        ~span {
          background-color: ${colors.primaire};
          color: #FFFFFF;
          svg {
            fill: #FFFFFF;
          }
        }
      }
    }
    span {
      width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      cursor: pointer;
      border: 3px solid #00205a;
      border-radius: 10px;
      line-height: 1;
      text-align: center;
      
      &.noSvg {
       width: auto;
        height: auto;
        padding: .5rem;
        font-size: 1rem;
      }
      
      svg {
        width: 95px;
        display: block;
        fill: #363636;
        margin-bottom: .5rem;
      }
    }
  }
  
  .fields {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    
    &.two {
      width: 48%;
    }
    
    input {
      width: 100%;
      font-size: 1.1rem;
      padding: .5rem;
      border: 1px solid #888;
      box-sizing: border-box;
      border-radius: 30px;
    }
  }
  
  .rgpd {
    p {
      margin-bottom: .5rem;
    }
  }

  .checkboxConfirm {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    
    label {
      margin-left: 1rem;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  
  .obligatoire {
    text-align: right;
    font-size: .8rem;
    margin-top: .5rem;
  }
  
  .error-message {
    color: darkred;
  }
`;

export default Form;