import React from 'react';
import {ErrorMessage} from "@hookform/error-message";

const Caracteristiques = ({typeBien, register, errors, clickNextStep4, step4}) => {
  return (
    <section className="group">
      <h3>Caractéristiques du bien</h3>
      <div className="fields">
        <div className="field two">
          <label htmlFor="contact_mandat_estimation_surface">Surface du bien en m²*</label>
          <input
            type="number"
            min="0"
            name="surface"
            id="contact_mandat_estimation_surface"
            placeholder="Exemple : 50"
            ref={register({
              required: "Veuillez indiquer la surface du bien",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="surface"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
        {typeBien === "maison" ? (
          <div className="field two">
            <label htmlFor="contact_mandat_estimation_surfaceTerrain">Surface du terrain du bien en m²</label>
            <input
              type="number"
              min="0"
              name="surfaceTerrain"
              id="contact_mandat_estimation_surfaceTerrain"
              placeholder="Exemple : 250"
            />
          </div>
        ) : null}

        <div className="field two">
          <label htmlFor="contact_mandat_estimation_nbPieces">Nombre de pièces à vivre du bien*</label>
          <input
            type="number"
            min="0"
            name="nbPieces"
            id="contact_mandat_estimation_nbPieces"
            placeholder="Exemple : 3"
            ref={register({
              required: "Veuillez indiquer le nombre de pièces à vivre du bien",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="nbPieces"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
        <div className="field two">
          <label htmlFor="contact_mandat_estimation_nbChambres">Nombre de chambres du bien*</label>
          <input
            type="number"
            min="0"
            name="nbChambres"
            id="contact_mandat_estimation_nbChambres"
            placeholder="Exemple : 2"
            ref={register({
              required: "Veuillez indiquer le nombre de chambres du bien",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="nbChambres"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
      </div>
      {step4 ? null : <button type="button" title="Etape suivante" onClick={clickNextStep4}>Etape suivante</button>}

    </section>
  )
}

export default Caracteristiques;