import styled from "styled-components";
import colors from "./helpers/colors";
import fonts from "./helpers/fonts";

const TitleH1 = styled.h1`
  text-align: center;
  color: ${colors.primaire};
  font-family: ${fonts.bold};
  margin: 1rem 0 2rem;
  
  span {
    font-family: ${fonts.bold};
    font-size: 1.3rem;
  }
`;

export default TitleH1;