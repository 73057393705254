import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyles from "./styles/GlobalStyles";
import "react-image-gallery/styles/scss/image-gallery.scss";
import 'react-phone-number-input/style.css'
import Layout from "./components/layout/Layout";
import {HashRouter} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles/>
    <HashRouter>
      <Layout>
        <App />
        <div id="modalRoot"/>
      </Layout>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
