import styled from "styled-components";
import colors from "../../helpers/colors";
import fonts from "../../helpers/fonts";

const Form = styled.form`
  margin: 0;
  div {
    text-align: left;
    margin: 0 0 1rem;
  }
  label {
    display: inline-block;
    text-align: left;
    margin-bottom: .5rem;
    color: ${colors.primaire};
  }
  input {
    width: 100%;
    padding: .8rem .5rem;
    border: 1px solid #cccccc;
    box-shadow: 0 3px 4px rgba(0,0,0,.12);
  }
  textarea {
    width: 100%;
    padding: .5rem;
    border: 1px solid #cccccc;
    height: 140px;
    line-height: 2;
    font-family: ${fonts.main};
    box-shadow: 0 2px 3px rgba(0,0,0,.15);
  }
  
  p {
    text-align: left;
    font-size: .8rem;
    margin-bottom: 1rem;
  }
`;

export default Form;