import React from "react";
import range from "lodash/range";
import {ReactComponent as FirstArrow} from "../../svg/first_arrow.svg";
import {ReactComponent as LastArrow} from "../../svg/last_arrow.svg";
import {ReactComponent as PreviousArrow} from "../../svg/left_arrow.svg";
import {ReactComponent as NextArrow} from "../../svg/right_arrow.svg";
import {Container, Element} from "../../styles/Pagination";

const getNumbersToDisplay = (totalPages, params) => {
  const pagesCutOff = 8;
  const ceiling = Math.ceil(pagesCutOff / 2);
  const floor = Math.floor(pagesCutOff / 2);

  let originalRange;
  if (totalPages <= pagesCutOff) originalRange = range(1, totalPages + 1);
  else if (params <= ceiling) originalRange = range(1, pagesCutOff + 1);
  else if (params + floor >= totalPages)
    originalRange = range(totalPages - pagesCutOff + 1, totalPages + 1);
  else
    originalRange = range(params - ceiling + 1, params + floor + 1);

  return [...originalRange];
};

const Pagination = ({ totalPages, params, setParams, scrollTop }) => {
  const renderLinks = () => {
    const pageInt = parseInt(params); //facilite les calculs ci-dessous
    const totalPagesInt = parseInt(totalPages); //facilite les calculs ci-dessous
    let links = [];
    let key = 0;

    const handleClickOnPage = (value) => {
      setParams((params) => ({
        ...params,
        page: value,
      }));
      scrollTop()
    };

    //Première page
    links.push(
      pageInt <= 1 ? (
        <Element key={key} disabled>
          <FirstArrow />
        </Element>
      ) : (
        <Element
          key={key}
          data-value={1}
          onClick={(e) => handleClickOnPage(e.target.dataset.value)}
          title="Retour à la première page"
        >
          <FirstArrow />
        </Element>
      )
    );
    key++;

    //Page précédente
    links.push(
      pageInt <= 1 ? (
        <Element key={key} disabled>
          <PreviousArrow />
        </Element>
      ) : (
        <Element
          key={key}
          data-value={pageInt - 1}
          onClick={(e) => handleClickOnPage(e.target.dataset.value)}
          title="Aller à la page précédente"
        >
          <PreviousArrow />
        </Element>
      )
    );
    key++;

    //Pages numérotées
    const numbersToDisplay = getNumbersToDisplay(totalPagesInt, pageInt);
    numbersToDisplay.forEach((number) => {
      links.push(
        number === pageInt ? (
          <Element key={key} activated>
            {number}
          </Element>
        ) : (
          <Element
            key={key}
            data-value={number}
            onClick={(e) => handleClickOnPage(e.target.dataset.value)}
            title={`Aller à la page ${number}`}
          >
            {number}
          </Element>
        )
      );
      key++;
    });

    //Page suivante
    links.push(
      pageInt >= totalPagesInt ? (
        <Element key={key} disabled>
          <NextArrow />
        </Element>
      ) : (
        <Element
          key={key}
          data-value={pageInt + 1}
          onClick={(e) => handleClickOnPage(e.target.dataset.value)}
          title="Aller à la page suivante"
        >
          <NextArrow />
        </Element>
      )
    );
    key++;

    //Dernière page
    links.push(
      pageInt >= totalPagesInt ? (
        <Element key={key} disabled>
          <LastArrow />
        </Element>
      ) : (
        <Element
          key={key}
          data-value={totalPagesInt}
          onClick={(e) => handleClickOnPage(e.target.dataset.value)}
          title="Aller à la dernière page"
        >
          <LastArrow />
        </Element>
      )
    );

    return links;
  };

  return <Container>{renderLinks()}</Container>;
};

Pagination.defaultProps = {
  totalPages: 1,
  currentPage: 1,
  params: {},
};

export default Pagination;
