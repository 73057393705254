import styled from "styled-components";
import colors from "../../helpers/colors";
import fonts from "../../helpers/fonts";

const Contact = styled.form`
  margin: 2rem 0 0;
  .item {
    text-align: left;
    margin: 1rem 0;
  }
  label {
    display: inline-block;
    text-align: left;
    margin-bottom: .5rem;
    color: ${colors.primaire};
  }
  input {
    width: 100%;
    padding: .5rem;
    border: 1px solid #cccccc;
    box-shadow: 0 2px 3px rgba(0,0,0,.15);
  }
  textarea {
    width: 100%;
    padding: .5rem;
    border: 1px solid #cccccc;
    height: 140px;
    line-height: 2;
    font-family: ${fonts.main};
    box-shadow: 0 2px 3px rgba(0,0,0,.15);
  }
  
  p {
    text-align: left;
    font-size: .8rem;
    margin-bottom: 1rem;
  }
  
  .error-message {
    color: darkred;
  }
  
  .form-message.error {
    background-color: red;
    color: #FFFFFF;
    padding: .5rem;
  }
  .form-message.success {
    background-color: green;
    color: #FFFFFF;
    padding: .5rem;
  }
  
  .PhoneInput {
    margin: 0;
  }
  .PhoneInputCountry {
    margin: 0;
  }
  .PhoneInputCountrySelectArrow {
    margin-right: .3rem;
  }
`;

export default Contact;