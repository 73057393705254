import styled from "styled-components";
import {Container} from "../../Dropdown";
import colors from "../../helpers/colors";
import breakpoints from "../../helpers/breakpoints";

const Filters = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  
  .order {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .views {
    display: flex;
    align-items: center;
    
    button {
      display: flex;
      align-items: center;
      margin: 0;
      border: 0;
      background-color: #ffffff;
      width: 85px;
      height: 35px;
      box-shadow: 0 2px 4px rgba(0,0,0,.4);
      padding: 0 .5rem;
      cursor: pointer;
      
      svg {
        display: block;
        fill: ${colors.primaire};
        margin-right: .5rem;
      }
      
      &.active {
        position: relative;
        z-index: 1;
        background-color: ${colors.primaire};
        color: #ffffff;
        cursor: default;
        
        svg {
          fill: #ffffff;
        }
      }
    }
  }
  
  ${Container} {
    position: relative;
    width: 200px;
    p {
      width: 100%;
      cursor: pointer;
      background-color: #ffffff;
      padding: 0 .8rem;
      line-height: 35px;
      box-shadow: 0 2px 4px rgba(0,0,0,.4);
    }
    
    li {
      padding: .8rem;
      cursor: pointer;
      
      &:hover {
        background-color: rgba(0,0,0,.1);
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.largestTablet}) {
    align-items: flex-end;
    .order {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: ${breakpoints.mobileL}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .order {
      align-items: center;
      margin-bottom: 1rem;
    }
  }
`;

export default Filters;