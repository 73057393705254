import styled from "styled-components";
import breakpoints from "../helpers/breakpoints";

const LogoCitya = styled.section`
  position: relative;
  margin-top: 3rem;
  outline: none;
  z-index: 99;
  
  picture {
    display: block;
    width: 100px;
    height: 100px;
    
    svg {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: ${breakpoints.largestTablet}) {
    margin-top: 0;
    picture {
      width: 70px;
      height: 70px;

      svg {
        width: 70px;
        height: 70px;
      }
    }
  }
`;

export default LogoCitya;