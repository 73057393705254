import styled from "styled-components";
import breakpoints from "../helpers/breakpoints";

const Content = styled.section`
  display: flex;
  align-items: center;
  padding-left: 7rem;
  height: 100%;

  @media (max-width: ${breakpoints.largestTabletL}) {
    display: none;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    
    &.active {
      display: flex;
    }
  }
`;

export default Content;