import React from 'react';
import {Container} from "../../styles/Footer";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <Container>
      <ul>
        <li>
          <Link to="/">
            Mentions légales
          </Link>
        </li>
        <li>
          <Link to="/">
            Conditions générales d'utilisation
          </Link>
        </li>
      </ul>
    </Container>
  )
}

export default Footer;