import React from 'react';
import {ReactComponent as Piscine} from "../../svg/piscine.svg";
import {ReactComponent as Balcon} from "../../svg/balcon.svg";
import {ReactComponent as Terrasse} from "../../svg/terrace.svg";
import {ReactComponent as Stationnement} from "../../svg/parking.svg";
import {ReactComponent as Bus} from "../../svg/bus.svg";
import {ReactComponent as Ecole} from "../../svg/ecole.svg";
import {ReactComponent as BelleExpo} from "../../svg/sun.svg";

const Other_Caracteristiques = ({typeBien, clickNextStep5, step5}) => {
  return (
    <section className="group">
      <h3>Autres caractéristiques</h3>
      <div className="choices">
        <label className="choice" htmlFor="contact_mandat_estimation_piscine">
          <input type="checkbox" name="piscine" id="contact_mandat_estimation_piscine"
                 value="1"/>
          <span>
            <Piscine/>
            Piscine
          </span>
        </label>
        {typeBien === "appartement" ? (
          <label className="choice" htmlFor="contact_mandat_estimation_balcon">
            <input type="checkbox" name="balcon" id="contact_mandat_estimation_balcon"
                   value="1"/>
            <span>
            <Balcon/>
            Balcon
          </span>
          </label>
        ) : (
          <label className="choice" htmlFor="contact_mandat_estimation_terrasse">
            <input type="checkbox" name="terrasse" id="contact_mandat_estimation_terrasse"
                   value="1"/>
            <span>
            <Terrasse/>
            Terrasse
          </span>
          </label>
        )}
        <label className="choice" htmlFor="contact_mandat_estimation_parking">
          <input type="checkbox" name="parking" id="contact_mandat_estimation_parking"
                 value="1"/>
          <span>
            <Stationnement/>
            Stationnement
          </span>
        </label>
        <label className="choice" htmlFor="contact_mandat_estimation_transports">
          <input type="checkbox" name="transports" id="contact_mandat_estimation_transports"
                 value="1"/>
          <span>
            <Bus/>
            Transports
          </span>
        </label>
        <label className="choice" htmlFor="contact_mandat_estimation_ecoles">
          <input type="checkbox" name="ecoles" id="contact_mandat_estimation_ecoles"
                 value="1"/>
          <span>
            <Ecole/>
            Ecoles
          </span>
        </label>
        <label className="choice" htmlFor="contact_mandat_estimation_exposition">
          <input type="checkbox" name="exposition" id="contact_mandat_estimation_exposition"
                 value="1"/>
          <span>
            <BelleExpo/>
            Belle exposition
          </span>
        </label>
      </div>
      {step5 ? null : <button type="button" title="Etape suivante" onClick={clickNextStep5}>Etape suivante</button>}
    </section>
  )
}

export default Other_Caracteristiques;