import styled from "styled-components";
import colors from "./helpers/colors";

const Header = styled.header`
  position: relative;
  overflow: hidden;
  border-bottom: 3px solid ${colors.secondaire};
  
  .loading {
    display: block;
    background-color: #FFFFFF;
    height: 500px;
  }

  img {
    position: relative;
    float: left;
    max-width: 100%;
    width: 100%;
    max-height: 650px;
    object-fit: cover;
    z-index: 1;
  }

  section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.10);

    .inner {
      position: relative;
      width: 1280px;
      max-width: 100%;
      margin: 0 auto;
      padding-left: 66px;
      padding-right: 66px;
      clear: both;
    }

    .vertical-center {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      p {
        color: #FFFFFF;
        font-size: 2.5em;
        text-align: center;
      }
    }
  }
`;

export default Header;