import styled from "styled-components";

const Container = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,.7);
	display: grid;
	place-items: center;
	padding: 2rem;
	box-sizing: border-box;
	z-index: 200;
	overflow: auto;
  
`;

export default Container;