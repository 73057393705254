import React, {useEffect, useRef, useState} from 'react';
import TitleH1 from "../styles/TitleH1";
import {Container, Filters} from "../styles/Pages/Adverts";
import Dropdown from "../components/Adverts/Dropdown";
import {ReactComponent as ListView} from '../svg/list_view.svg';
import {ReactComponent as MapView} from '../svg/map_view.svg';
import ListCards from "../components/Adverts/ListCards";
import {ITEMS_PER_PAGE} from "../constants/pagination";
import ApiService from "../services/ApiService";
import {useHistory, useLocation} from "react-router-dom";
import {findTypesBienFromTypologie, generateQueryString, slugify} from "../helpers/utils";
import useDebounce from "../hooks/useDebounce";
import Formulaire from "../components/SearchForm/Formulaire";
import Map from "../components/Adverts/Map";
import useOutsideClick from "../hooks/useOutsideClick";
import {Suggestion} from "../styles/SearchForm";
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../styles/helpers/colors";

const initialFilters = {
  ville: "",
  typologie: null,
  budgetMin: "",
  budgetMax: "",
  nbrePiecesMin: "",
  nbrePiecesMax: "",
  surfaceMin: "",
  surfaceMax: "",
  orderMandat: "DESC",
};

const Adverts = () => {
  const { promiseInProgress } = usePromiseTracker();

  const query = new URLSearchParams(useLocation().search)
  const [viewList, setViewList] = useState(true);
  const [viewMap, setViewMap] = useState(false);
  const [annonces, setAnnonces] = useState(null);
  const [totalAnnonces, setTotalAnnonces] = useState(null);
  const [currentPage, setCurrentPage] = useState();
  const [lastPage, setLastPage] = useState();
  const [cityInputValue, setCityInputValue] = useState("");
  const [citiesSuggestions, setCitiesSuggestions] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({
    villeSlug: query.get('villeSlug') ?? '',
    villeInsee: query.get('villeInsee') ?? '',
    typologie: findTypesBienFromTypologie(query.get("typologie")),
    budgetMin: query.get('budgetMin') ?? '',
    budgetMax: query.get('budgetMax') ?? '',
    nbrePiecesMin: query.get('nbrePiecesMin') ?? '',
    nbrePiecesMax: query.get('nbrePiecesMax') ?? '',
    surfaceMin: query.get('surfaceMin') ?? '',
    surfaceMax: query.get('surfaceMax') ?? '',
    orderPrix: query.get('orderPrix') ?? '',
    orderMandat: query.get('orderMandat') ?? '',
    orderSurfaceBien: query.get('orderSurfaceBien') ?? '',
    page: query.get('page') ?? 1,
  });
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const refDropdown = useRef();

  useOutsideClick(refDropdown, () => {
    if (open) setOpen(false);
  });

  const isInitialMount = useRef(true);
  let history = useHistory();

  // Si on modifie le state query.toString, on recharge l'API
  const debouncedSearchTerm = useDebounce(currentFilters, 500);
  useEffect( () => {
    if (debouncedSearchTerm) {
      let urlQueryString = generateQueryString(
        currentFilters,
      );
      history.replace({pathname: '/annonces', search: urlQueryString})
      const params = {
        bpvf: 1,
        "order[dateMandat]": currentFilters.orderMandat !== ""
          ? currentFilters.orderMandat
          : 'DESC',
        "order[vente.prix]": currentFilters.orderPrix !== ""
          ? currentFilters.orderPrix
          : null,
        "order[surfaceBien]": currentFilters.orderSurfaceBien !== ""
          ? currentFilters.orderSurfaceBien
          : null,
        "exists[images]": 1,
        "exists[vente]": 1,
        pagination: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        page: currentFilters.page !== ""
          ? currentFilters.page
          : 1,
        typologie: findTypesBienFromTypologie(currentFilters.typologie),
        "vente.prix[gte]":
          currentFilters.budgetMin !== ""
            ? currentFilters.budgetMin
            : null,
        "vente.prix[lte]":
          currentFilters.budgetMax !== ""
            ? currentFilters.budgetMax
            : null,
        "nbrePieces[gte]":
          currentFilters.nbrePiecesMin !== ""
            ? currentFilters.nbrePiecesMin
            : null,
        "nbrePieces[lte]":
          currentFilters.nbrePiecesMax !== ""
            ? currentFilters.nbrePiecesMax
            : null,
        "surfaceBien[gte]":
          currentFilters.surfaceMin !== "" ? currentFilters.surfaceMin : null,
        "surfaceBien[lte]":
          currentFilters.surfaceMax !== "" ? currentFilters.surfaceMax : null,
        "ville.slug": currentFilters.villeSlug !== "" ? currentFilters.villeSlug : null,
        "ville.slugInsee": currentFilters.villeInsee !== "" ? currentFilters.villeInsee : null,
      };

      const callApi = async params => {
        const response = await trackPromise(ApiService.getAll(params));
        if (response) {
          setAnnonces(response);
          setTotalAnnonces(response.totalItems);
          setLastPage(parseInt(response.lastPage?.page ?? 1));
        } else {
          console.log("Une erreur est survenue");
        }
      };
      callApi(params);

    }
  }, [debouncedSearchTerm])

  //s'il y a le slug d'une ville dans l'URL en paramètre, il faut aller chercher le nom de cette ville pour préremplir le champ de filtre avec
  useEffect(() => {
    if (!currentFilters.villeSlug || !currentFilters.villeInsee) return;
    const fetchVille = async () => {
      const data = await ApiService.getVilles({
        "order[ville]": "ASC",
        ville: currentFilters.villeInsee !== "" ? currentFilters.villeInsee : currentFilters.villeSlug,
      });
      if (data[0]) setCityInputValue(data[0].villeInsee);
    };
    fetchVille();
  }, []);

  //on passe l'initialMount à false dès que celui-ci est terminé
  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  /**
   * AUTOSUGGEST MANAGEMENT
   * ----------------------
   */

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = async (value) => {
    const inputValue = slugify(value);
    if (inputValue.length === 0) return [];
    const data = await ApiService.getVilles({
      "order[ville]": "ASC",
      pagination: 1,
      page: 1,
      ville: inputValue,
    });
    return data.filter((city) => slugify(city.ville).startsWith(inputValue));
  };

  // When suggestion is clicked, Autosuggest needs to populate the input based on the clicked suggestion.
  // Teach Autosuggest how to calculate the input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.ville;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion, { isHighlighted }) => (
    <Suggestion isHighlighted={isHighlighted}>
      <p className="codePostal">
        {suggestion.codePostal}
      </p>
      <p className="ville">{suggestion.ville}</p>
    </Suggestion>
  );

  // Use your imagination to render suggestions box.
  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return <section {...containerProps} className={`react-autosuggest__suggestions-container localisation ${open ? 'active' : ''}`} id="react-autowhatever-1" role="listbox">{children}</section>;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = async ({ value }) => {
    const cities = await getSuggestions(value);
    setCitiesSuggestions(cities);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => setCitiesSuggestions([]);

  // Se déclenche lorsque l'utilisateur choisit une ville dans la liste de suggestions
  const onSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.slugInsee !== null) {
      setCurrentFilters((currentFilters) => ({
        ...currentFilters,
        villeInsee: suggestion.slugInsee,
        villeSlug: null,
        page: 1,
      }));
    } else {
      setCurrentFilters((currentFilters) => ({
        ...currentFilters,
        villeSlug: suggestion.slug,
        villeInsee: null,
        page: 1,
      }));
    }
  };
  /**
   * ----------------------
   * ----------------------
   */

  const handleCityChange = (value) => {
    if (value === "")
      setCurrentFilters((currentFilters) => ({
        ...currentFilters,
        ville: null,
      }));
    setCityInputValue(value);
    setCurrentPage(1);
  };
  const handleClickListView = () => {
    setViewList(true);
    setViewMap(false);
  }
  const handleClickMapView = () => {
    setViewMap(true);
    setViewList(false);
  }
  const handleFiltersReset = () => {
    setCurrentFilters(initialFilters);
    setCityInputValue("");
    setCurrentPage(1);
  };

  const renderView = () => {
    if (viewList === true) {
      return (
        <>
          <ListCards annonces={annonces} promiseInProgress={promiseInProgress} scrollTop={scrollTop} params={currentFilters} setParams={setCurrentFilters} totalPages={lastPage}/>
        </>
      )
    } else {
      return (
        <Map annonces={annonces} currentFilters={currentFilters} />
      )
    }
  }

  return(
    <React.Fragment>
      <Formulaire
        setCurrentFilters={setCurrentFilters}
        currentFilters={currentFilters}
        setAnnonces={setAnnonces}
        isInitialMount={isInitialMount.current}
        input={{
          label: 'Vous recherchez un bien dans une ville <span class="nowrap">précise ?</span>',
          placeholder: "Saisissez, puis sélectionnez",
          value: cityInputValue,
          onChange: (value) => handleCityChange(value),
        }}
        autosuggestArgs={{
          suggestions: citiesSuggestions,
          onSuggestionsFetchRequested,
          onSuggestionsClearRequested,
          getSuggestionValue,
          renderSuggestion,
          renderSuggestionsContainer,
          onSuggestionSelected,
        }}
        hasAutosuggest={true}
        onReset={handleFiltersReset}
      />
      <main>
        <TitleH1>Achat immobilier {currentFilters.page > 1 ? `page ${currentFilters.page}` : null}</TitleH1>
        <Container>
          <Filters>
            <section className="order">
              <p>{promiseInProgress === true ? (<PulseLoader color={colors.secondaire} size={8}/>) : totalAnnonces} biens en ventes</p>
              <Dropdown setCurrentFilters={setCurrentFilters} currentFilters={currentFilters}/>
            </section>
            <section className="views">
              <button className={viewList ? 'active' : null} onClick={handleClickListView}>
                <ListView/>
                <p>Liste</p>
              </button>
              {annonces !== null ? (
                <button className={viewMap ? 'active' : null} onClick={handleClickMapView}>
                  <MapView/>
                  <p>Carte</p>
                </button>
              ) : null}
            </section>
          </Filters>
          {renderView()}
        </Container>
      </main>

    </React.Fragment>
  )
}

export default Adverts;