import styled from "styled-components";

const Container = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
  margin: 0 0 1rem;
`;

export default Container;
