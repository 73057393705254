import React from 'react';
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import fr from 'react-phone-number-input/locale/fr'
import {ErrorMessage} from "@hookform/error-message";

const Coordonnees = ({control, register, errors}) => {
  return(
    <section className="group">
      <h3>Vos coordonnées</h3>
      <div className="fields">
        <div className="field two">
          <label htmlFor="nom">Nom*</label>
          <input
            type="text"
            name="nom"
            id="nom"
            placeholder="Veuillez indiquer votre nom"
            ref={register({
              required: "Veuillez indiquer votre nom",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="nom"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
        <div className="field two">
          <label htmlFor="prenom">Prénom*</label>
          <input
            type="text"
            name="prenom"
            id="prenom"
            placeholder="Veuillez indiquer votre prenom"
            ref={register({
              required: "Veuillez indiquer votre prénom",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="prenom"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
        <div className="field two">
          <label htmlFor="contact_mandat_estimation_email">Adresse e-mail*</label>
          <input
            type="email"
            name="email"
            id="contact_mandat_estimation_email"
            placeholder="Veuillez indiquer votre adresse e-mail"
            ref={register({
              required: "Veuillez indiquer votre adresse e-mail",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
        <div className="field two">
          <label htmlFor="telephone">Numéro de téléphone*</label>
          <PhoneInputWithCountry
            defaultCountry="FR"
            control={control}
            labels={fr}
            name="telephone"
            id="telephone"
            placeholder="00 00 00 00 00"
            rules={{ required: true }}
          />
          <ErrorMessage
            errors={errors}
            name="telephone"
            render={({messages}) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error-message">
                    {message}
                  </p>
                ))
                : null;
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default Coordonnees;