import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Container, Content, Flex, Form, Input, Item} from "../../styles/SearchForm";
import Dropdown from "./Dropdown";
import useOutsideClick from "../../hooks/useOutsideClick";
import {ReactComponent as Refresh} from '../../svg/refreshing.svg';
import {ReactComponent as Affiner} from '../../svg/affiner.svg';
import Autosuggest from "react-autosuggest";
import types_bien from "../../dataset/types_bien";

const Formulaire = ({currentFilters,setCurrentFilters,isInitialMount, hasAutosuggest, autosuggestArgs, input, onReset}) => {
  const [TitleTypeBien, setTitleTypeBien] = useState("Type de bien");
  const [TitleBudget, setTitleBudget] = useState("Budget");
  const [TitleNbrPieces, setTitleNbrPieces] = useState("Nombre de pièce(s)");
  const [TitleSurface, setTitleSurface] = useState("Surface");
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const refDropdown = useRef();
  const [affiner, setAffiner] = useState(false);

  useOutsideClick(refDropdown, () => {
    if (open) setOpen(false);
  });

  const handleTypeBienChange = (value) => {
    if (value === currentFilters.typologie)
      setCurrentFilters((currentFilters) => ({
        ...currentFilters,
        typologie: null,
      }));
    else
      setCurrentFilters((currentFilters) => ({
        ...currentFilters,
        typologie: value,
        page: 1,
      }));
  };

  const handleBudgetMinChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      budgetMin: value,
      page: 1,
    }));
  };
  const handleBudgetMaxChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      budgetMax: value,
      page: 1,
    }));
  };

  const handleNbrePiecesMinChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      nbrePiecesMin: value,
      page: 1,
    }));
  };
  const handleNbrePiecesMaxChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      nbrePiecesMax: value,
      page: 1,
    }));
  };

  const handleSurfaceMinChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      surfaceMin: value,
      page: 1,
    }));
  };
  const handleSurfaceMaxChange = (value) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      surfaceMax: value,
      page: 1,
    }));
  };


  useEffect(() => {
    // useEffect pour les types de bien
    if (currentFilters.typologie === "appartement") {
      setTitleTypeBien("Appartement");
    } else if (currentFilters.typologie === "maison") {
      setTitleTypeBien("Maison");
    } else if (currentFilters.typologie === "immeuble") {
      setTitleTypeBien("Immeuble");
    } else if (currentFilters.typologie === "parking") {
      setTitleTypeBien("Parking");
    } else if (currentFilters.typologie === "professionnel") {
      setTitleTypeBien("Professionnel");
    } else if (currentFilters.typologie === "terrain") {
      setTitleTypeBien("Terrain");
    } else {
      setTitleTypeBien("Type de bien");
    }

    // useEffect pour les budgets
    let valBudgetMin = parseFloat(currentFilters.budgetMin),
      valBudgetMax = parseFloat(currentFilters.budgetMax),
      formatBudgetMin = new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(currentFilters.budgetMin),
      formatBudgetMax = new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(currentFilters.budgetMax);
    if (currentFilters.budgetMin == "" && currentFilters.budgetMax == "") {
      setTitleBudget("Budget");
    } else if (currentFilters.budgetMin != "" && currentFilters.budgetMax == "") {
      setTitleBudget(`${formatBudgetMin} € min`)
    } else if (currentFilters.budgetMin == "" && currentFilters.budgetMax != "") {
      setTitleBudget(`${formatBudgetMax} € max`)
    } else if (currentFilters.budgetMin != "" && currentFilters.budgetMax != "") {
      if (valBudgetMin === valBudgetMax) {
        setTitleBudget(`${formatBudgetMax} €`)
      } else if (valBudgetMax < valBudgetMin || valBudgetMin > valBudgetMax) {
        setTitleBudget(`Min. plus grand que Max.`)
      } else {
        setTitleBudget(`${formatBudgetMin} € à ${formatBudgetMax} €`)
      }
    } else {
      setTitleBudget("Budget");
    }

    // useEffect pour les pièces
    let valPiecesMin = parseFloat(currentFilters.nbrePiecesMin),
      valPiecesMax = parseFloat(currentFilters.nbrePiecesMax);
    if (currentFilters.nbrePiecesMin == "" && currentFilters.nbrePiecesMax == "") {
      setTitleNbrPieces("Nombres de pièce(s)");
    } else if (currentFilters.nbrePiecesMin != "" && currentFilters.nbrePiecesMax == "") {
      setTitleNbrPieces(`${currentFilters.nbrePiecesMin} pièce(s) min`)
    } else if (currentFilters.nbrePiecesMin == "" && currentFilters.nbrePiecesMax != "") {
      setTitleNbrPieces(`${currentFilters.nbrePiecesMax} pièce(s) max`)
    } else if (currentFilters.nbrePiecesMin != "" && currentFilters.nbrePiecesMax != "") {
      if (valPiecesMin === valPiecesMax) {
        setTitleNbrPieces(`${currentFilters.nbrePiecesMax} pièce(s)`)
      } else if (valPiecesMax < valPiecesMin || valPiecesMin > valPiecesMax) {
        setTitleNbrPieces(`Min. plus grand que Max.`)
      } else {
        setTitleNbrPieces(`${currentFilters.nbrePiecesMin} à ${currentFilters.nbrePiecesMax} pièce(s)`)
      }
    } else {
      setTitleNbrPieces("Nombres de pièce(s)");
    }

    // useEffect pour la surface
    let valSurfaceMin = parseFloat(currentFilters.surfaceMin),
      valSurfaceMax = parseFloat(currentFilters.surfaceMax);
    if (currentFilters.surfaceMin == "" && currentFilters.surfaceMax == "") {
      setTitleSurface("Surface");
    } else if (currentFilters.surfaceMin != "" && currentFilters.surfaceMax == "") {
      setTitleSurface(`${currentFilters.surfaceMin} m² min`)
    } else if (currentFilters.surfaceMin == "" && currentFilters.surfaceMax != "") {
      setTitleSurface(`${currentFilters.surfaceMax} m² max`)
    } else if (currentFilters.surfaceMin != "" && currentFilters.surfaceMax != "") {
      if (valSurfaceMin === valSurfaceMax) {
        setTitleSurface(`${currentFilters.surfaceMax} m²`)
      } else if (valSurfaceMax < valSurfaceMin || valSurfaceMin > valSurfaceMax) {
        setTitleSurface(`Min. plus grand que Max.`)
      } else {
        setTitleSurface(`${currentFilters.surfaceMin} à ${currentFilters.surfaceMax} m²`)
      }
    } else {
      setTitleSurface("Surface");
    }
  }, [currentFilters])

  const renderInput = () => {
    return hasAutosuggest ? (
      <Autosuggest
        suggestions={autosuggestArgs.suggestions}
        onSuggestionsFetchRequested={
          autosuggestArgs.onSuggestionsFetchRequested
        }
        onSuggestionsClearRequested={
          autosuggestArgs.onSuggestionsClearRequested
        }
        getSuggestionValue={autosuggestArgs.getSuggestionValue}
        renderSuggestion={autosuggestArgs.renderSuggestion}
        renderSuggestionsContainer={autosuggestArgs.renderSuggestionsContainer}
        onSuggestionSelected={autosuggestArgs.onSuggestionSelected}
        renderInputComponent={(inputProps) => (
          <input
            type="search"
            id="position"
            name="input"
            className="localisation"
            {...inputProps}
          />
        )}
        highlightFirstSuggestion={true}
        focusInputOnSuggestionClick={false}
        inputProps={{
          placeholder: input.placeholder,
          value: input.value,
          onChange: (e, { newValue }) => input.onChange(newValue),
        }}
      />
    ) : (
      <input
        type="search"
        id="position"
        name="input"
        placeholder={input.placeholder}
        value={input.value}
        onChange={(e) => input.onChange(e.target.value)}
      />
    );
  };

  const renderTypesBien = () =>
    types_bien.map((type, i) => {
      return (
        <Checkbox
          key={i}
          data-value={type.slug}
          hoverable={currentFilters.typologie !== type.slug}
          onClick={(e) => handleTypeBienChange(e.target.dataset.value)}
        >
          {type.libelle}
        </Checkbox>
      );
    });

  return(
    <Container>
      <p id="btnAffiner" onClick={() => setAffiner(!affiner)}>
        <Affiner/>
        Affiner votre recherche
      </p>
      <Content className={affiner ? "active" : null}>
        <Form name="search_annonces">
          <Item>
            <div className="itemContent">
              <label htmlFor="position">Localisation</label>
              {renderInput()}
            </div>
          </Item>
          <Item>
            <div className="itemContent">
              <label>Type de bien</label>
              <Dropdown id="type" title={TitleTypeBien}>
                {renderTypesBien()}
              </Dropdown>
            </div>
          </Item>
          <Item>
            <div className="itemContent">
              <label>Budget</label>
              <Dropdown id="budget" title={TitleBudget}>
                <Flex>
                  <Input
                    type="number"
                    step="100"
                    min="0"
                    name="prix_min"
                    placeholder="Min €"
                    value={currentFilters.budgetMin}
                    onChange={(e) => handleBudgetMinChange(e.target.value)}
                  />
                  <Input
                    type="number"
                    step="100"
                    min="100"
                    name="prix_max"
                    placeholder="Max €"
                    value={currentFilters.budgetMax}
                    onChange={(e) => handleBudgetMaxChange(e.target.value)}
                  />
                </Flex>
              </Dropdown>
            </div>
          </Item>
          <Item>
            <div className="itemContent">
              <label>Pièce(s)</label>
              <Dropdown id="piece" title={TitleNbrPieces}>
                <Flex>
                  <Input
                    type="number"
                    step="1"
                    min="0"
                    name="piece_min"
                    placeholder="Pièce minimum"
                    value={currentFilters.nbrePiecesMin}
                    onChange={(e) => handleNbrePiecesMinChange(e.target.value)}
                  />
                  <Input
                    type="number"
                    step="1"
                    min="1"
                    name="piece_max"
                    placeholder="Pièce maximum"
                    value={currentFilters.nbrePiecesMax}
                    onChange={(e) => handleNbrePiecesMaxChange(e.target.value)}
                  />
                </Flex>
              </Dropdown>
            </div>
          </Item>
          <Item>
            <div className="itemContent">
              <label>Surface</label>
              <Dropdown id="surface" title={TitleSurface}>
                <Flex>
                  <Input
                    type="number"
                    step="5"
                    min="0"
                    name="surface_min"
                    placeholder="Surface min m²"
                    value={currentFilters.surfaceMin}
                    onChange={(e) => handleSurfaceMinChange(e.target.value)}
                  />
                  <Input
                    type="number"
                    step="5"
                    min="5"
                    name="surface_max"
                    placeholder="Surface max m²"
                    value={currentFilters.surfaceMax}
                    onChange={(e) => handleSurfaceMaxChange(e.target.value)}
                  />
                </Flex>
              </Dropdown>
            </div>
          </Item>
          <Button type="button" title="Réinitialiser les filtres" onClick={() => onReset()}><Refresh/></Button>
        </Form>
      </Content>
    </Container>
  )
}

export default Formulaire;