import styled, {css} from "styled-components";
import colors from "../helpers/colors";

const Checkbox = styled.p`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  width: 100%;
  padding: 1rem;
  
  ${({hoverable}) =>
    hoverable &&
    css`
      background-color: #FFFFFF;
      cursor: pointer;
    `
  };
`;

Checkbox.defaultProps = {
backgroundColor: colors.tertiaire,
color: '#000000',
};
export default Checkbox;