import React, {useEffect, useRef, useState} from 'react';
import useOutsideClick from "../../hooks/useOutsideClick";
import {Container, Content} from "../../styles/Dropdown";

const Dropdown = ({setCurrentFilters, currentFilters}) => {
  const [open, setOpen] = useState(false);
  const [titleFilter, setTitleFilter] = useState("Date d'ajout");
  const toggle = () => setOpen(!open);
  const refDropdown = useRef();

  useOutsideClick(refDropdown, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    if (currentFilters.orderMandat !== null) {
      setTitleFilter("Date d'ajout")
    }
  }, [currentFilters])

  const handlePrixASC = () => {
    setTitleFilter("Prix croissant");
    toggle(!open)
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      orderPrix: "ASC",
      orderMandat: null,
    }));
  };

  const handlePrixDESC = () => {
    setTitleFilter("Prix décroissant");
    toggle(!open)
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      orderPrix: "DESC",
      orderMandat: null,
    }));
  };

  const handleSurfaceASC = () => {
    setTitleFilter("Surface croissant");
    toggle(!open)
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      orderSurfaceBien: 'ASC',
      orderPrix: null,
      orderMandat: null,
    }));
  };

  const handleSurfaceDESC = () => {
    setTitleFilter("Surface décroissant");
    toggle(!open)
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      orderSurfaceBien: 'DESC',
      orderPrix: null,
      orderMandat: null,
    }));
  };

  return (
    <Container>
      <p onClick={() => toggle(!open)}>{titleFilter}</p>
      <Content className={open ? 'active' : ''} ref={refDropdown}>
        <ul>
          <li onClick={() => {handlePrixASC()}}>Prix croissant</li>
          <li onClick={() => {handlePrixDESC()}}>Prix décroissant</li>
          <li onClick={() => {handleSurfaceASC()}}>Surface croissant</li>
          <li onClick={() => {handleSurfaceDESC()}}>Surface décroissant</li>
        </ul>
      </Content>
    </Container>
  )
}

export default Dropdown;