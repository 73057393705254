import styled from "styled-components";
import colors from "../../helpers/colors";

const MapBienIci = styled.section`
  margin: 2rem 0;
  height: 700px;

  .f4map-panes, .kimono-map {
    height: 700px !important;
    width: 100% !important;
  }

  .kimono-map #kimono-expandMapButton {
    right: 0;
  }

  .kimono-map p {
    margin: 0;
    color: ${colors.primaire};
  }
  .kimono-map a {
    color: ${colors.primaire} !important;
  }

  .infoWindow {
    display: flex;
    align-items: center;
    min-width: 400px;
    width: 100%;
    overflow: hidden;
    color: ${colors.primaire};
    
    a {
      color: ${colors.primaire};
    }

    picture {
      max-width: 150px;
      margin-right: .5rem;

      img {
        width: 100%;
        height: auto;
      }
    }

    .ville {
      color: #777777;
    }
  }
`;

export default MapBienIci;