import React from 'react';
import ReactDOM from 'react-dom';
import {Container, Window} from "../styles/Modal";

const Modal = ({ isOpen, handleClick, children }) => {
	return !isOpen ?
		null :
		ReactDOM.createPortal(
			<Container onClick={e => handleClick(e)}>
				<Window onClick={e => e.stopPropagation()}>
					<span className="cross" onClick={e => handleClick(e)}></span>
					{children}
				</Window>
			</Container>,
			document.querySelector('#modalRoot')
		);
};

export default Modal;