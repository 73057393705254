import styled from "styled-components";
import fonts from "../../helpers/fonts";
import colors from "../../helpers/colors";

const Agence = styled.section`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: .5rem 0;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  
  img {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
  }
  
  address {
    line-height: 1.5;
    font-style: normal;
    a {
      font-family: ${fonts.bold};
      color: ${colors.primaire};
    }
  }
`;

export default Agence;