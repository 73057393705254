import React from 'react';
import {ReactComponent as One} from "../../svg/piece_one.svg";
import {ReactComponent as Two} from "../../svg/piece_two.svg";
import {ReactComponent as Three} from "../../svg/piece_three.svg";
import {ReactComponent as Four} from "../../svg/piece_four.svg";
import {ReactComponent as Five} from "../../svg/piece_five.svg";
import {ReactComponent as Six} from "../../svg/piece_six.svg";

const IconPieces = ({data}) => {
  if (data.nbrePieces <= 1) {
    return <One/>
  } else if (data.nbrePieces === 2) {
    return <Two/>
  } else if (data.nbrePieces === 3) {
    return <Three/>
  } else if (data.nbrePieces === 4) {
    return <Four/>
  } else if (data.nbrePieces === 5) {
    return <Five/>
  } else if (data.nbrePieces >= 6) {
    return <Six/>
  }
}

export default IconPieces;