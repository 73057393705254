import React, {useEffect, useRef} from 'react';
import {CardActu} from "../../styles/Pages/Accueil";

const Actu = ({data}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!contentRef.current)
      return;
    //on va mettre le contenu dans une div virtuelle puis lire le textContent pour enlever automatiquement les tags
    let content = contentRef.current.textContent || contentRef.current.innerText || ""
    const div = document.createElement("div");
    div.innerHTML = content;
    content = div.textContent || div.innerText || "";
    contentRef.current.innerText = content;
  }, []);

  const truncateStr = (str, n, useWordBoundary) => {
    if (str.length <= n)
      return str;
    const subString = str.substr(0, n - 1); // the original check
    const spaceDelimitersPos = subString.lastIndexOf(" ");
    const hyphenDelimitersPos = subString.lastIndexOf("-");
    return (useWordBoundary ?
      subString.substr(0, spaceDelimitersPos > 0 ? spaceDelimitersPos : hyphenDelimitersPos)
      : subString) + "...";
  };

  const openWindow = () => {
    window.open(`https://www.aucoeurdelimmo.com/actualites/${data.categories[0].slug}/${data.slug}`)
  };

  return (
    <>
      <CardActu onClick={openWindow}>
        <picture>
          <img src={`https://api.aucoeurdelimmo.com${data.photo.contentUrl}`} alt={data.titre} loading="lazy" />
        </picture>
        <section>
          <p className="titleACDLI">{truncateStr(data.titre, 80, true)}</p>
          <p ref={contentRef}>{truncateStr(data.contenu, 150, true)}</p>
        </section>
      </CardActu>
    </>
  )
}

export default Actu;