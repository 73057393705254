import styled from "styled-components";
import colors from "../helpers/colors";
import {Container} from "../Dropdown";
import breakpoints from "../helpers/breakpoints";

const Item = styled.section`
  border-right: 1px solid ${colors.tertiaire};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 19%;
  max-width: 19%;
  height: 100%;
  padding: 0 1rem;
  position: relative;
  
  &:last-child {
    border-right: 0;
  }
  
  .itemContent {
    height: auto;
    width: 100%;
  }
  
  label {
    color: hsla(0,0%,100%,.7);
    text-transform: uppercase;
    font-size: .8rem;
  }
  
  #position {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: .5rem 0 0;
    width: 100%;
    color: #ffffff;
    
    &::placeholder {
      color: #ffffff;
      font-size: .8rem;
    }
  }
  
  ${Container} { 
    .title {
      color: #ffffff;
      cursor: pointer;
    }
  }
  
  .localisation {
    border-radius: 10px;
    max-height: 375px;
    width: 400px;
    margin-top: 0.25rem;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    margin-left: -0.25rem;
    overflow: hidden scroll;
    box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
    position: absolute;
    top: 100%;
    z-index: 2;
    
    &.active {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.largestTabletL}) {
    flex: 100%;
    max-width: 100%;
    border-right: 0;
    width: 100%;
    padding: .8rem 1rem;
  }
`;

export default Item;