import styled from "styled-components";

const DetailBref = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0 0;
  
  p {
    width: 33.333333%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  svg {
    width: 35px;
    height: 35px;
  }
`;

export default DetailBref;