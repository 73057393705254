import styled from "styled-components";
import colors from "../helpers/colors";

const Window = styled.section`
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 245, 245);
  align-items: center;
  text-align: center;
  padding: 2rem 3rem;
  box-sizing: border-box;
  position: relative;
  border-top: 5px solid ${colors.primaire};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  max-width: 1280px;

  > h3 {
    margin-bottom: 2rem;
  }

  > section:not(:last-child) {
    margin-bottom: 1rem;
    align-items: center;
    text-align: center;
  }

  .cross {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    width: 20px;
    height: 20px;
    transition: transform 0.2s linear 0s;

    &:after, &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 3px;
      background-color: ${colors.primaire};
      border-radius: 5px;
      top: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
`;

export default Window;