import React from 'react';
import {Detail} from "../../styles/Pages/Advert";
import {ReactComponent as Chevron} from "../../svg/chevron.svg";
import {separateThousands} from "../../helpers/utils";

const AdvertDetailPrix = ({data}) => {
  return (
    <Detail prix>
      <h2>Prix :</h2>
      <section>
        <p>
          <Chevron/>
          <span>
              Les charges honoraires{" "}
            {data.vente.chargesHonoraires == 2 ? "sont partagées entre le vendeur et l'acquéreur"
              : data.vente.chargesHonoraires == 1 ? "reviennent à l'acquéreur" : "reviennent au vendeur"}
            </span>
        </p>
        {(data.vente.chargesHonoraires == 1 || data.vente.chargesHonoraires == 2) &&
        !!data.vente.honorairesPourcentageAcquereur && (
          <p>
            <Chevron/>
            <span>
              {separateThousands(Math.round(data.vente.prix * 100) / 100)} € (dont{" "}
              {separateThousands(
                Math.round(data.vente.honorairesPourcentageAcquereur * 100) / 100
              )}
              % d'honoraires à charge acquéreur)
            </span>
          </p>
        )}
        {(data.vente.chargesHonoraires == 1 || data.vente.chargesHonoraires == 2) &&
        !!data.vente.honorairesPourcentageAcquereur && (
          <p>
            <Chevron/>
            <span>
                Soit{" "}
              {separateThousands(Math.round(data.vente.prix * 100) / 100) -
              (separateThousands(
                Math.round(data.vente.honorairesPourcentageAcquereur * 100) / 100
                ) *
                separateThousands(Math.round(data.vente.prix * 100) / 100)) /
              100}{" "}
              € net vendeur
            </span>
          </p>
        )}
        {data.vente.chargesHonoraires == 0 && (
          <p>
            <Chevron/>
            <span>
              {separateThousands(Math.round(data.vente.prix * 100) / 100)} €
            </span>
          </p>
        )}
        {!data.vente.copropriete && !!data.vente.chargesMensuelles && (
          <p>
            <Chevron/>
            <span>
              Charges mensuelles de{" "}
              {separateThousands(
                Math.round(data.vente.chargesMensuelles * 100) / 100
              )}{" "}
              €
            </span>
          </p>
        )}
        {!!data.vente.taxeFonciere && (
          <p>
            <Chevron/>
            <span>
              Taxe foncière annuelle de{" "}
              {separateThousands(Math.round(data.vente.taxeFonciere * 100) / 100)} €
            </span>
          </p>
        )}
      </section>
      <section>
        {!!data.vente.chargesMensuelles && (
          <p>
            <Chevron/>
            <span>
              Charges annuelles de{" "}
              {separateThousands(
                Math.round(data.vente.chargesMensuelles * 12 * 100) / 100
              )}{" "}
              €
            </span>
          </p>
        )}
        {!!data.vente.coproQuotePart && (
          <p>
            <Chevron/>
            <span>
              Quote-part prévisionnelle dépenses courantes de{" "}
              {separateThousands(Math.round(data.vente.coproQuotePart * 100) / 100)}{" "}
              €/an
            </span>
          </p>
        )}
        {!!parseInt(data.vente.coproNombreLots) && (
          <p>
            <Chevron/>
            <span>
              Contient {data.vente.coproNombreLots} lot
              {data.vente.coproNombreLots > 1 ? "s" : ""} (incluant lots secondaires)
            </span>
          </p>
        )}
        {!!data.vente.coproNombreLotsSecondaires && (
          <p>
            <Chevron/>
            <span>
              Contient {vente.coproNombreLotsSecondaires} lot
              {data.vente.coproNombreLotsSecondaires > 1 ? "s" : ""} secondaire
              {data.vente.coproNombreLotsSecondaires > 1 ? "s" : ""}
            </span>
          </p>
        )}
        {!!parseInt(data.vente.coproNumLot) && (
          <p>
            <Chevron/>
            <span>
              Lot n°{data.vente.coproNumLot}
            </span>
          </p>
        )}
        {!!data.vente.coproManAdhoc && (
          <p>
            <Chevron/>
            <span>
              Demande de désignation d’un mandataire ad hoc en cours
            </span>
          </p>
        )}
        {!!data.vente.coproAdminProv && (
          <p>
            <Chevron/>
            <span>Demande de désignation d’un administrateur provisoire en cours</span>
          </p>
        )}
        {!!data.vente.coproDesExp && (
          <p>
            <Chevron/>
            <span>Demande de désignation d’experts en cours</span>
          </p>
        )}
        {!!data.vente.coproProcDilig && (
          <p>
            <Chevron/>
            <span>Procédures diligentées en cours</span>
          </p>
        )}
        {!!data.vente.coproProcDiligTexte && (
          <p>
            <Chevron/>
            <span>{data.vente.coproProcDiligTexte}</span>
          </p>
        )}
      </section>
    </Detail>
  )
}

export default AdvertDetailPrix;