import React, {useState} from 'react';
import {LogoBpvf, LogoCitya, Menu} from "../../styles/Menu";
import Bpvf from "../../public/images/bpvf.png";
import {Link} from "react-router-dom";

const Navbar = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <Menu>
      <LogoCitya>
        <picture>
          <Link to="/" replace>
            <img src="https://media.sas-arche.com/entities_logos_default/236.svg" alt="Citya Immobilier" />
          </Link>
        </picture>
      </LogoCitya>
      <section className={`navigation ${isNavVisible ? 'active' : ''}`}>
        <button onClick={toggleNav} className={isNavVisible ? 'active' : ''} title="Afficher le menu" id="nav-toggle">
          <span></span>
        </button>
        <Link to="/annonces">
          Annonces
        </Link>
        {/*<Link to="/estimer">
          Estimer
        </Link>*/}
        {/*<Link to="/contact">
          Contacter
        </Link>*/}
        <LogoBpvf>
          <Link to="/" replace>
            <img src={Bpvf} alt="Banque Populaire Val de France" />
          </Link>
        </LogoBpvf>
      </section>
      <button onClick={toggleNav} className={isNavVisible ? 'active' : ''} title="Afficher le menu" id="nav-toggle">
        <span></span>
      </button>
    </Menu>
  )
}

export default Navbar;