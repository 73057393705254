export default {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  largestTablet: '767px',
  tabletL: '1024px',
  largestTabletL: '1023px',
  laptop: '1200',
  largestLaptop: '1199px',
  laptopL: '1440px',
  largestLaptopL: "1439px",
  desktop: '2560px'
};
