import React, {useEffect, useState} from 'react';
import {Container, Content, Form} from "../styles/Pages/Estimer";
import TitleH1 from "../styles/TitleH1";
import {slugify} from "../helpers/utils";
import ApiService from "../services/ApiService";
import {Button, Suggestion} from "../styles/SearchForm";
import {useForm} from "react-hook-form";

import Localisation from "../components/Estimer/Localisation";
import TypeBien from "../components/Estimer/TypeBien";
import Caracteristiques from "../components/Estimer/Caracteristiques";
import Other_Caracteristiques from "../components/Estimer/Other_Caracteristiques";
import Construction from "../components/Estimer/Construction";
import Etat from "../components/Estimer/Etat";
import Coordonnees from "../components/Estimer/Coordonnees";
import Hidden from "../components/Estimer/Hidden";
import Header from "../styles/Header";
import ImgHeader from "../public/images/header_accueil.jpg";

const Estimer = () => {
  const [open, setOpen] = useState(false);
  const [cityInputValue, setCityInputValue] = useState("");
  const [citiesSuggestions, setCitiesSuggestions] = useState([]);
  const {control, register, handleSubmit, errors} = useForm({ criteriaMode: "all" });
  const [formMessage, setFormMessage] = useState({});
  const [isEnvoiEnCours, setIsEnvoiEnCours] = useState(false);
  const [estimation, setEstimation] = useState({
    adresse: '',
    codePostal: '',
    ville: '',
    latitude: '',
    longitude: '',
  });
  const [typeBien, setTypeBien] = useState('');
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [step7, setStep7] = useState(false);

  useEffect(() => {
    const formMessage = document.querySelector(".form-message");
    let timeout;
    if (
      formMessage &&
      (formMessage.classList.contains("success") ||
        formMessage.classList.contains("error"))
    ) {
      timeout = setTimeout(() => {
        formMessage.classList.remove("success", "error");
        setFormMessage({});
      }, 20000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [formMessage]);

  const onSubmit = data => {
    ApiService.postEstimation(data).then(result => {
      console.log(result)
    })
    .catch(error => {
      console.log(error);
    })
  }

  /**
   * AUTOSUGGEST MANAGEMENT
   * ----------------------
   */

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = async (value) => {
    const inputValue = slugify(value);
    if (inputValue.length === 0) return [];
    const data = await ApiService.getAdresses({
      q: inputValue,
    });
    return data.filter((city) => slugify(city.properties.label).startsWith(inputValue));
  };

  // When suggestion is clicked, Autosuggest needs to populate the input based on the clicked suggestion.
  // Teach Autosuggest how to calculate the input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.properties.label;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion, { isHighlighted }) => (
    <Suggestion isHighlighted={isHighlighted}>
      <p className="codePostal">
        {suggestion.properties.postcode} {suggestion.properties.city}
      </p>
      <p className="ville">{suggestion.properties.name}</p>
    </Suggestion>
  );

  // Use your imagination to render suggestions box.
  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return <section {...containerProps} className={`react-autosuggest__suggestions-container localisation ${open ? 'active' : ''}`} id="react-autowhatever-1" role="listbox">{children}</section>;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = async ({ value }) => {
    const cities = await getSuggestions(value);
    setCitiesSuggestions(cities);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => setCitiesSuggestions([]);

  // Se déclenche lorsque l'utilisateur choisit une ville dans la liste de suggestions
  const onSuggestionSelected = (e, { suggestion }) => {
    if (suggestion !== null) {
      setCityInputValue(suggestion.properties.label)
      setEstimation((estimation) => ({
        ...estimation,
        adresse: suggestion.properties.name,
        codePostal: suggestion.properties.postcode,
        ville: suggestion.properties.city,
        longitude: suggestion.geometry.coordinates[0],
        latitude: suggestion.geometry.coordinates[1],
      }))
      setStep2(!step2);
    }
  };

  /**
   * ----------------------
   * ----------------------
   */

  const handleAdresseChange = (value) => {
    setCityInputValue(value);
  };

  const handleTypeBienChange = () => {
    setStep3(true);
  }
  const clickNextStep4 = () => {
    setStep4(true);
  }
  const clickNextStep5 = () => {
    setStep5(true);
  }
  const handleConstructionChange = () => {
    setStep6(true);
  }
  const handleEtatChange = () => {
    setStep7(true);
  }

  return (
    <>
      <Header>
        <picture>
          <img src={ImgHeader} alt="Banque Populaire X Citya Immobilier"/>
        </picture>
      </Header>
      <main>
        <TitleH1>Estimez votre bien immobilier</TitleH1>
        <Container>
          <Content>
            <h2>Vous souhaitez faire estimer votre bien immobilier ?<br/>Prenez 2 minutes pour remplir ce formulaire,<br/>puis obtenez une estimation instantanément !</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Localisation
                autosuggestArgs={{
                  suggestions: citiesSuggestions,
                  onSuggestionsFetchRequested,
                  onSuggestionsClearRequested,
                  getSuggestionValue,
                  renderSuggestion,
                  renderSuggestionsContainer,
                  onSuggestionSelected,
                }}
                handleAdresseChange={handleAdresseChange}
                cityInputValue={cityInputValue}
                register={register}
                errors={errors}
              />

              {step2 ? <TypeBien register={register} handleTypeBienChange={handleTypeBienChange} setTypeBien={setTypeBien} /> : null}
              {step3 ? <Caracteristiques register={register} typeBien={typeBien} errors={errors} clickNextStep4={clickNextStep4} step4={step4}/> : null}
              {step4 ? <Other_Caracteristiques register={register} typeBien={typeBien} clickNextStep5={clickNextStep5} step5={step5} /> : null}
              {step5 ? <Construction register={register} handleConstructionChange={handleConstructionChange}/> : null}
              {step6 ? <Etat register={register} handleEtatChange={handleEtatChange}/> : null}
              {step7 ? (
                <>
                  <Coordonnees control={control} register={register} errors={errors}/>
                  <div className="rgpd">
                    <p>Vos informations sont traitées par Citya Immobilier afin de vous proposer une estimation de votre bien et de vous informer sur l'évolution du marché immobilier par email ou téléphone.</p>
                    <p>Citya Immobilier pourra également vous recontacter par SMS ou téléphone, sauf opposition de votre part, dans le cadre d'une enquête de satisfaction sur l'utilisation de nos services.</p>
                    <p>Pour exercer vos droits conformément à la loi « Informatique et Libertés » :</p>
                    <div className="checkboxConfirm">
                      <input type="checkbox" id="contact_mandat_estimation_consentementEnqueteSatisfaction" name="contact_mandat_estimation[consentementEnqueteSatisfaction]" value="1" />
                      <label htmlFor="contact_mandat_estimation_consentementEnqueteSatisfaction">Je souhaite être recontacté par Téléphone ou SMS par Citya immobilier dans le cadre d'une enquête de satisfaction</label>
                    </div>
                    <div className="checkboxConfirm">
                      <input type="checkbox" id="contact_mandat_estimation_consentementPrixVente"
                             name="contact_mandat_estimation[consentementPrixVente]" value="1" />
                      <label htmlFor="contact_mandat_estimation_consentementPrixVente">Je souhaite recevoir des informations
                        sur les prix de vente près de chez moi</label>
                    </div>
                  </div>
                  <Button type="submit">
                    {isEnvoiEnCours ? "Calcul en cours..." : "Lancer le calcul"}
                  </Button>
                </>
              ) : null}
              <Hidden estimation={estimation}/>
              <p className="obligatoire">
                *champ obligatoire
              </p>
            </Form>
          </Content>
        </Container>
      </main>
    </>
  )
}

export default Estimer;