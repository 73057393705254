import React from 'react';

const Etat = ({handleEtatChange}) => {
  return(
    <section className="group">
      <label>Etat du bien</label>
      <div className="choices">
        <label htmlFor="contact_mandat_estimation_etat_0" className="choice">
          <input onChange={handleEtatChange} type="radio" id="contact_mandat_estimation_etat_0" name="etat" value="renover"/>
          <span className="noSvg">A rénover</span>
        </label>
        <label htmlFor="contact_mandat_estimation_etat_1" className="choice">
          <input onChange={handleEtatChange} type="radio" id="contact_mandat_estimation_etat_1" name="etat" value="moyen"/>
          <span className="noSvg">Moyen</span>
        </label>
        <label htmlFor="contact_mandat_estimation_etat_2" className="choice">
          <input onChange={handleEtatChange} type="radio" id="contact_mandat_estimation_etat_2" name="etat" value="bon"/>
          <span className="noSvg">Bon</span>
        </label>
        <label htmlFor="contact_mandat_estimation_etat_3" className="choice">
          <input onChange={handleEtatChange} type="radio" id="contact_mandat_estimation_etat_3" name="etat" value="parfait"/>
          <span className="noSvg">Parfait</span>
        </label>
      </div>
    </section>
  )
}

export default Etat;