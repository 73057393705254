import styled from "styled-components";
import breakpoints from "../../helpers/breakpoints";
import fonts from "../../helpers/fonts";

const Content = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  article {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1rem;
    p {
      margin: 0 0 1rem;
    }
    
    strong {
      font-family: ${fonts.bold};
    }
  }
  
  picture {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1rem;
    img {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.largestTabletL}) {
    article, picture {
      flex: 100%;
      max-width: 100%;
    }
    
    picture img {
      padding: 1rem 0;
    }
  }
`;

export default Content;