import React from 'react';

const Construction = ({handleConstructionChange}) => {
  return(
    <section className="group">
      <label>Année de construction du bien</label>
      <div className="choices">
        <label htmlFor="contact_mandat_estimation_construction_0" className="choice">
          <input onChange={handleConstructionChange} type="radio" id="contact_mandat_estimation_construction_0" name="construction" value="beton"/>
          <span className="noSvg">Habitation en béton</span>
        </label>
        <label htmlFor="contact_mandat_estimation_construction_1" className="choice">
          <input onChange={handleConstructionChange} type="radio" id="contact_mandat_estimation_construction_1" name="construction" value="recent"/>
          <span className="noSvg">Habitation +10 ans</span>
        </label>
        <label htmlFor="contact_mandat_estimation_construction_2" className="choice">
          <input onChange={handleConstructionChange} type="radio" id="contact_mandat_estimation_construction_2" name="construction" value="-10 ans"/>
          <span className="noSvg">Habitation -10 ans</span>
        </label>
        <label htmlFor="contact_mandat_estimation_construction_3" className="choice">
          <input onChange={handleConstructionChange} type="radio" id="contact_mandat_estimation_construction_3" name="construction" value="caractere"/>
          <span className="noSvg">Habitation de caractère</span>
        </label>
        <label htmlFor="contact_mandat_estimation_construction_4" className="choice">
          <input onChange={handleConstructionChange} type="radio" id="contact_mandat_estimation_construction_4" name="construction" value="autre"/>
          <span className="noSvg">Autre</span>
        </label>
      </div>
    </section>
  )
}

export default Construction;