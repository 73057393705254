import styled from "styled-components";
import colors from "../../helpers/colors";
import fonts from "../../helpers/fonts";

const Card = styled.article`
  background-color: #FFFFFF;
  box-shadow: 0 3px 4px rgba(0,0,0,.28);
  transition: box-shadow .2s ease-out;
  
  &:hover {
    box-shadow: 0 3px 4px rgba(0,0,0,.48);
  }
  
  picture {
    display: block;
    height: 260px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  section {
    padding: 1rem;
  }
  
  .title {
    color: ${colors.primaire};
    line-height: 1.3;
    margin-bottom: 1rem;
    
    a {
      color: ${colors.primaire};
    }
    
    strong {
      font-family: ${fonts.bold};
    }
    span {
      color: #999999;
      font-size: .9rem;
    }
  }
  
  .price {
    line-height: 1.3;
    color: ${colors.primaire};
    strong {
      font-size: 1.2rem;
      font-family: ${fonts.bold};
    }
    span {
      color: #999999;
      font-size: .9rem;
    }
  }
`;

export default Card;